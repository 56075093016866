import {
  AddUserTicket,
  CloseTicketRequest,
  PaginatedTickets,
  UpdateTicketStatus,
  UserTicket,
} from "@/store/modules/ticket/ticket.types";
import axios from "axios";
import { AddTicketRequest } from "i-tracking-proto/js/v1/ticket/rpc_add_ticket_pb";
import { UpdateTicketRequest } from "i-tracking-proto/js/v1/ticket/rpc_update_ticket_pb";

export async function addTicket(
  authKey: string,
  payload: AddUserTicket
): Promise<UserTicket> {
  const request = new AddTicketRequest();
  request.setCategory(payload.category);
  request.setIssue(payload.issue);
  request.setTicketNumber(payload.ticketNumber);
  request.setDescription(payload.description);

  if (payload.assignee) request.setAssignee(payload.assignee);
  request.setPriority(payload.priority);
  request.setProduct(payload.product);
  request.setSite(payload.site);

  const response = await axios.post<UserTicket>(
    "/v1/ticket",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateTicket(
  authKey: string,
  payload: UserTicket
): Promise<UserTicket> {
  const request = new UpdateTicketRequest();
  request.setId(payload.id);
  request.setCategory(payload.category);
  request.setIssue(payload.issue);
  request.setTicketNumber(payload.ticketNumber);
  request.setDescription(payload.description);

  if (payload.assignee) request.setAssignee(payload.assignee);
  request.setPriority(payload.priority);
  request.setProduct(payload.product);
  request.setSite(payload.site);

  const response = await axios.patch<UserTicket>(
    `/v1/ticket`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function closeTicket(
  authKey: string,
  payload: CloseTicketRequest
): Promise<UserTicket> {
  const response = await axios.patch<UserTicket>(
    `/v1/ticket/close`,
    Object.assign({ id: payload.ticket.id }, payload.details),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteTicket(
  authKey: string,
  payload: UserTicket
): Promise<void> {
  await axios.delete<void>(`/v1/ticket/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function changeTicketStatus(
  authKey: string,
  payload: UpdateTicketStatus
): Promise<UserTicket> {
  return (
    await axios.put<UserTicket>(
      `/v1/ticket/status`,
      {
        id: payload.ticket.id,
        status: payload.status,
      },
      {
        headers: { "Content-Type": "application/json", Authorization: authKey },
      }
    )
  ).data;
}

export async function getTickets(
  authKey: string,
  page = 1,
  offset = 10
  // query = ""
): Promise<PaginatedTickets> {
  const response = await axios.get<PaginatedTickets>(
    `/v1/tickets?page=${page}&limit=${offset}`,
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function getTopTickets(authKey: string): Promise<UserTicket[]> {
  const response = await axios.get<UserTicket[]>("/v1/dashboard/new-tickets", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addTicket,
  getTickets,
  getTopTickets,
  updateTicket,
  deleteTicket,
  changeTicketStatus,
  closeTicket,
};
