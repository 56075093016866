import userService from "@/services/user.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import { AddSystemUser, UserTypes, SystemUser } from "./user.types";

@Module({ namespaced: true })
class UserModule extends VuexModule {
  public [UserTypes.USERS]: SystemUser[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addUserRef = 0;

  public [UserTypes.USER_DATA]: SystemUser = {
    id: -1,
    firstname: "",
    lastname: "",
    username: "",
    name: "",
    email: "",
    phone: "",
    department: "",
    role: "",
    active: false,
    createdAt: new Date(),
  };

  public [UserTypes.ADD_USER_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [UserTypes.DELETE_USER_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [UserTypes.UPDATE_USER_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [UserTypes.LOADING_USERS_STATE] = false;
  public [UserTypes.LOADING_USER_PROFILE] = false;
  public [UserTypes.USER_PROFILE]: SystemUser = {
    id: -1,
    firstname: "",
    lastname: "",
    username: "",
    name: "",
    email: "",
    phone: "",
    department: "",
    role: "",
    active: false,
    createdAt: new Date(),
  };

  @Mutation
  public [UserTypes.SET_ADD_USER_DIALOG_REF](ref: number): void {
    this.addUserRef = ref;
  }

  @Mutation
  public [UserTypes.SET_TICKET_USER_DATA](data: SystemUser): void {
    this[UserTypes.USER_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [UserTypes.SET_USER_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [UserTypes.SET_LOADING_USER](isLoadingCard: boolean): void {
    this[UserTypes.LOADING_USERS_STATE] = isLoadingCard;
  }

  @Mutation
  public [UserTypes.SET_LOADING_USER_PROFILE](isLoadingProfile: boolean): void {
    this[UserTypes.LOADING_USER_PROFILE] = isLoadingProfile;
  }

  @Mutation
  public [UserTypes.SET_USER_PROFILE](profile: SystemUser): void {
    this[UserTypes.USER_PROFILE] = profile;
  }

  // Add Card
  @Mutation
  public [UserTypes.SET_ADD_USER_DIALOG](isAddingCardDialog: boolean): void {
    this[UserTypes.ADD_USER_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [UserTypes.INSERT_USERS](users: SystemUser[]): void {
    this[UserTypes.USERS].splice(0, this[UserTypes.USERS].length);
    this[UserTypes.USERS].push(...users);
  }

  @Mutation
  public [UserTypes.SET_ADD_USER_LOADING](isAddingCardLoading: boolean): void {
    this[UserTypes.ADD_USER_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [UserTypes.SET_ADD_USER_ERROR](addError: string): void {
    this[UserTypes.ADD_USER_STATE].error.errorMessage = addError;
    this[UserTypes.ADD_USER_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [UserTypes.SET_DELETE_USER_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[UserTypes.DELETE_USER_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [UserTypes.SET_DELETE_USER_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[UserTypes.DELETE_USER_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [UserTypes.SET_DELETE_USER_ERROR](deleteError: string): void {
    this[UserTypes.DELETE_USER_STATE].error.errorMessage = deleteError;
    this[UserTypes.DELETE_USER_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [UserTypes.SET_UPDATE_USER_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[UserTypes.UPDATE_USER_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [UserTypes.SET_UPDATE_USER_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[UserTypes.UPDATE_USER_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [UserTypes.SET_UPDATE_USER_ERROR](updateError: string): void {
    this[UserTypes.UPDATE_USER_STATE].error.errorMessage = updateError;
    this[UserTypes.UPDATE_USER_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [UserTypes.INSERT_USER](user: SystemUser): void {
    const index = this[UserTypes.USERS].map((x) => x.id).indexOf(user.id);

    if (index > -1) {
      this[UserTypes.USERS].splice(index, 1, user);
    } else {
      this[UserTypes.USERS].splice(0, 0, user);
    }
  }

  // Remove Card
  @Mutation
  public [UserTypes.REMOVE_USER](user: SystemUser): void {
    const index = this[UserTypes.USERS].map((x) => x.id).indexOf(user.id);

    if (index > -1) {
      this[UserTypes.USERS].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [UserTypes.LOAD_USERS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[UserTypes.USERS].length > 0) return;

    this.context.commit(UserTypes.SET_LOADING_USER, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await userService.getUsers(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(UserTypes.INSERT_USERS, response);
      // this.context.commit(UserTypes.SET_USER_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading users" },
        { root: true }
      );
    } finally {
      this.context.commit(UserTypes.SET_LOADING_USER, false);
    }
  }

  // Load Cards
  @Action
  public async [UserTypes.LOAD_USER_PROFILE](): Promise<void> {
    if (this[UserTypes.USER_PROFILE].id != -1) return;

    this.context.commit(UserTypes.SET_LOADING_USER_PROFILE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await userService.getUserProfile(authHeader);
      this.context.commit(UserTypes.SET_USER_PROFILE, response);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading user profile" },
        { root: true }
      );
    } finally {
      this.context.commit(UserTypes.SET_LOADING_USER_PROFILE, false);
    }
  }
  // Add Card
  @Action
  public async [UserTypes.ADD_USER](user: AddSystemUser): Promise<void> {
    this.context.commit(UserTypes.SET_ADD_USER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await userService.addUser(authHeader, user);
      this.context.commit(UserTypes.INSERT_USER, fm);
      this.context.commit(
        UserTypes.SET_ADD_USER_DIALOG_REF,
        this.addUserRef + 1
      );
      this.context.commit(UserTypes.SET_ADD_USER_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "User Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(UserTypes.SET_ADD_USER_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [UserTypes.DELETE_USER](): Promise<void> {
    this.context.commit(UserTypes.SET_DELETE_USER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await userService.deleteUser(authHeader, this[UserTypes.USER_DATA]);
      this.context.commit(UserTypes.REMOVE_USER, this[UserTypes.USER_DATA]);
      this.context.commit(UserTypes.SET_DELETE_USER_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        UserTypes.SET_DELETE_USER_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(UserTypes.SET_DELETE_USER_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [UserTypes.UPDATE_USER](ticketUser: SystemUser): Promise<void> {
    this.context.commit(UserTypes.SET_UPDATE_USER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await userService.updateUser(authHeader, ticketUser);
      this.context.commit(UserTypes.INSERT_USER, fm);
      this.context.commit(UserTypes.SET_UPDATE_USER_DIALOG, false);

      // Update if user profile
      if (fm.id === this[UserTypes.USER_PROFILE].id) {
        this.context.commit(UserTypes.SET_USER_PROFILE, fm);
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Updated", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        UserTypes.SET_UPDATE_USER_ERROR,
        "Failed to update user"
      );
    } finally {
      this.context.commit(UserTypes.SET_UPDATE_USER_LOADING, false);
    }
  }
}

export default UserModule;
