
import ValidationMixin from "@/mixins/validation";
import {
  SignByUsernamePayload,
  SignInState,
  SignInTypes,
} from "@/store/modules/auth/auth.types";
import { Validator } from "@/types/validator";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Component({
  components: {
    ForgotPassword: () => import("@/components/auth/ForgotPassword.vue"),
  },
})
export default class Login extends Mixins(ValidationMixin) {
  public form: SignByUsernamePayload = { username: "", password: "" };
  public isValid = false;

  @Auth.State("signIn")
  public signInState!: SignInState;

  @Auth.Mutation(SignInTypes.SET_FORGOT_PASSWORD_DIALOG)
  public setForgotPassword!: (deleteCategory: boolean) => void;

  @Auth.State("secret")
  public signInSecret!: string | null;

  @Auth.Action(SignInTypes.SIGN_IN)
  private signIn!: (payload: SignByUsernamePayload) => Promise<void>;

  async handleLogin(): Promise<void> {
    await (this.$refs.loginForm as unknown as Validator).validate();
    if (this.isValid) await this.signIn(this.form);
  }
}
