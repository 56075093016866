import { DefaultState } from "@/types/types";

export type SystemProduct = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type AddSystemProduct = {
  name: string;
  description: string;
  active: boolean;
};

export type ProductsState = {
  products: SystemProduct[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetProductsResult = {
  products: SystemProduct[];
  pages: number;
};

export enum ProductTypes {
  // States
  PRODUCTS = "PRODUCTS",
  PRODUCT_DATA = "PRODUCT_DATA",
  ADD_PRODUCT_STATE = "ADD_PRODUCT_STATE",
  UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE",
  DELETE_PRODUCT_STATE = "DELETE_PRODUCT_STATE",
  LOADING_PRODUCTS_STATE = "LOADING_PRODUCTS_STATE",

  // Actions
  LOAD_PRODUCTS = "LOAD_PRODUCTS",
  ADD_PRODUCT = "ADD_PRODUCT",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",

  // Mutations
  SET_LOADING_PRODUCT = "SET_LOADING_PRODUCT",

  INSERT_PRODUCT = "INSERT_PRODUCT",
  INSERT_PRODUCTS = "INSERT_PRODUCTS",
  REMOVE_PRODUCT = "REMOVE_PRODUCT",

  SET_TICKET_PRODUCT_DATA = "SET_TICKET_PRODUCT_DATA",
  SET_PRODUCT_PAGES = "SET_PRODUCT_PAGES",
  SET_ADD_PRODUCT_DIALOG_REF = "SET_ADD_PRODUCT_DIALOG_REF",

  SET_ADD_PRODUCT_LOADING = "SET_ADD_PRODUCT_LOADING",
  SET_ADD_PRODUCT_DIALOG = "SET_ADD_PRODUCT_DIALOG",
  SET_ADD_PRODUCT_ERROR = "SET_ADD_PRODUCT_ERROR",

  SET_UPDATE_PRODUCT_LOADING = "SET_UPDATE_PRODUCT_LOADING",
  SET_UPDATE_PRODUCT_DIALOG = "SET_UPDATE_PRODUCT_DIALOG",
  SET_UPDATE_PRODUCT_ERROR = "SET_UPDATE_PRODUCT_ERROR",

  SET_DELETE_PRODUCT_LOADING = "SET_DELETE_PRODUCT_LOADING",
  SET_DELETE_PRODUCT_DIALOG = "SET_DELETE_PRODUCT_DIALOG",
  SET_DELETE_PRODUCT_ERROR = "SET_DELETE_PRODUCT_ERROR",
}
