import { Component, Vue } from "vue-property-decorator";

@Component
export class UpdateMixin extends Vue {
  public refreshing = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registration: any = null;
  public updateExists = false;

  created(): void {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  updateAvailable(event: any): void {
    this.registration = event.detail;
    this.updateExists = true;
  }

  // Called when the user accepts the update
  refreshApp(): void {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}
