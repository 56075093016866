import priorityService from "@/services/priority.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import {
  AddIssuePriority,
  PriorityTypes,
  IssuePriority,
} from "./priority.types";

@Module({ namespaced: true })
class PriorityModule extends VuexModule {
  public [PriorityTypes.PRIORITIES]: IssuePriority[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addPriorityRef = 0;

  public [PriorityTypes.PRIORITY_DATA]: IssuePriority = {
    id: -1,
    name: "",
    description: "",
    active: false,
    precedence: 0,
    createdAt: new Date(),
    zones: [],
  };

  public [PriorityTypes.ADD_PRIORITY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [PriorityTypes.DELETE_PRIORITY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [PriorityTypes.UPDATE_PRIORITY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [PriorityTypes.LOADING_PRIORITIES_STATE] = false;

  @Mutation
  public [PriorityTypes.SET_ADD_PRIORITY_DIALOG_REF](ref: number): void {
    this.addPriorityRef = ref;
  }

  @Mutation
  public [PriorityTypes.SET_TICKET_PRIORITY_DATA](data: IssuePriority): void {
    this[PriorityTypes.PRIORITY_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [PriorityTypes.SET_PRIORITY_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [PriorityTypes.SET_LOADING_PRIORITY](isLoadingCard: boolean): void {
    this[PriorityTypes.LOADING_PRIORITIES_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [PriorityTypes.SET_ADD_PRIORITY_DIALOG](
    isAddingCardDialog: boolean
  ): void {
    this[PriorityTypes.ADD_PRIORITY_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [PriorityTypes.INSERT_PRIORITYS](priorities: IssuePriority[]): void {
    this[PriorityTypes.PRIORITIES].splice(
      0,
      this[PriorityTypes.PRIORITIES].length
    );
    this[PriorityTypes.PRIORITIES].push(...priorities);
  }

  @Mutation
  public [PriorityTypes.SET_ADD_PRIORITY_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this[PriorityTypes.ADD_PRIORITY_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [PriorityTypes.SET_ADD_PRIORITY_ERROR](addError: string): void {
    this[PriorityTypes.ADD_PRIORITY_STATE].error.errorMessage = addError;
    this[PriorityTypes.ADD_PRIORITY_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [PriorityTypes.SET_DELETE_PRIORITY_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[PriorityTypes.DELETE_PRIORITY_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [PriorityTypes.SET_DELETE_PRIORITY_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[PriorityTypes.DELETE_PRIORITY_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [PriorityTypes.SET_DELETE_PRIORITY_ERROR](deleteError: string): void {
    this[PriorityTypes.DELETE_PRIORITY_STATE].error.errorMessage = deleteError;
    this[PriorityTypes.DELETE_PRIORITY_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [PriorityTypes.SET_UPDATE_PRIORITY_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[PriorityTypes.UPDATE_PRIORITY_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [PriorityTypes.SET_UPDATE_PRIORITY_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[PriorityTypes.UPDATE_PRIORITY_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [PriorityTypes.SET_UPDATE_PRIORITY_ERROR](updateError: string): void {
    this[PriorityTypes.UPDATE_PRIORITY_STATE].error.errorMessage = updateError;
    this[PriorityTypes.UPDATE_PRIORITY_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [PriorityTypes.INSERT_PRIORITY](priority: IssuePriority): void {
    const index = this[PriorityTypes.PRIORITIES]
      .map((x) => x.id)
      .indexOf(priority.id);

    if (index > -1) {
      this[PriorityTypes.PRIORITIES].splice(index, 1, priority);
    } else {
      this[PriorityTypes.PRIORITIES].splice(0, 0, priority);
    }
  }

  // Remove Card
  @Mutation
  public [PriorityTypes.REMOVE_PRIORITY](priority: IssuePriority): void {
    const index = this[PriorityTypes.PRIORITIES]
      .map((x) => x.id)
      .indexOf(priority.id);

    if (index > -1) {
      this[PriorityTypes.PRIORITIES].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [PriorityTypes.LOAD_PRIORITIES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[PriorityTypes.PRIORITIES].length > 0) return;

    this.context.commit(PriorityTypes.SET_LOADING_PRIORITY, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await priorityService.getPriorities(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(PriorityTypes.INSERT_PRIORITYS, response);
      // this.context.commit(PriorityTypes.SET_PRIORITY_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading priorities" },
        { root: true }
      );
    } finally {
      this.context.commit(PriorityTypes.SET_LOADING_PRIORITY, false);
    }
  }
  // Add Card
  @Action
  public async [PriorityTypes.ADD_PRIORITY](
    priority: AddIssuePriority
  ): Promise<void> {
    this.context.commit(PriorityTypes.SET_ADD_PRIORITY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await priorityService.addPriority(authHeader, priority);
      this.context.commit(PriorityTypes.INSERT_PRIORITY, fm);
      this.context.commit(
        PriorityTypes.SET_ADD_PRIORITY_DIALOG_REF,
        this.addPriorityRef + 1
      );
      this.context.commit(PriorityTypes.SET_ADD_PRIORITY_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Priority Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(PriorityTypes.SET_ADD_PRIORITY_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [PriorityTypes.DELETE_PRIORITY](): Promise<void> {
    this.context.commit(PriorityTypes.SET_DELETE_PRIORITY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await priorityService.deletePriority(
        authHeader,
        this[PriorityTypes.PRIORITY_DATA]
      );
      this.context.commit(
        PriorityTypes.REMOVE_PRIORITY,
        this[PriorityTypes.PRIORITY_DATA]
      );
      this.context.commit(PriorityTypes.SET_DELETE_PRIORITY_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        PriorityTypes.SET_DELETE_PRIORITY_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(PriorityTypes.SET_DELETE_PRIORITY_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [PriorityTypes.UPDATE_PRIORITY](
    ticketPriority: IssuePriority
  ): Promise<void> {
    this.context.commit(PriorityTypes.SET_UPDATE_PRIORITY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await priorityService.updatePriority(
        authHeader,
        ticketPriority
      );
      this.context.commit(PriorityTypes.INSERT_PRIORITY, fm);
      this.context.commit(PriorityTypes.SET_UPDATE_PRIORITY_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        PriorityTypes.SET_UPDATE_PRIORITY_ERROR,
        "Failed to update priority"
      );
    } finally {
      this.context.commit(PriorityTypes.SET_UPDATE_PRIORITY_LOADING, false);
    }
  }
}

export default PriorityModule;
