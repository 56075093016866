import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth/auth.module";
import Ticket from "./modules/ticket/ticket.module";
import Category from "./modules/category/category.module";
import Department from "./modules/department/department.module";
import Role from "./modules/role/role.module";
import Priority from "./modules/priority/priority.module";
import Product from "./modules/product/product.module";
import Site from "./modules/site/site.module";
import User from "./modules/user/user.module";
import Analytics from "./modules/analytics/analytics.module";
import Reports from "./modules/reports/reports.module";
import Zones from "./modules/zone/zone.module";
import { ZoneTypes } from "./modules/zone/zone.types";
import { ReportTypes } from "./modules/reports/reports.types";
import { state as RootState } from "@/store/modules/root.state";
import RootMutation from "@/store/modules/root.mutations";
import { SignInTypes } from "./modules/auth/auth.types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: RootState,
  mutations: RootMutation,
  modules: {
    [SignInTypes.MODULE]: Auth,
    Ticket,
    Category,
    Department,
    Role,
    Priority,
    Product,
    Site,
    User,
    Analytics,
    [ReportTypes.MODULE]: Reports,
    [ZoneTypes.MODULE]: Zones,
  },
});
