import { reversedash } from "@/filters/utils";
import {
  AnalyticFilter,
  Analytic,
  DashboardFilter,
  DashboardAnalytics,
} from "@/store/modules/analytics/analytics.types";
import axios from "axios";

export async function getProductAnalytics(
  authKey: string,
  payload: AnalyticFilter
): Promise<Analytic[]> {
  let url = "/v1/analytics/product";
  if (payload.start && payload.end) {
    url += `?startDate=${reversedash(payload.start)}&endDate=${reversedash(
      payload.end
    )}`;
  } else if (payload.start) {
    url += `?startDate=${reversedash(payload.start)}`;
  } else if (payload.end) {
    url += `?endDate=${reversedash(payload.end)}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getAssigneeAnalytics(
  authKey: string,
  payload: AnalyticFilter
): Promise<Analytic[]> {
  let url = "/v1/analytics/assignee";
  if (payload.start && payload.end) {
    url += `?startDate=${reversedash(payload.start)}&endDate=${reversedash(
      payload.end
    )}`;
  } else if (payload.start) {
    url += `?startDate=${reversedash(payload.start)}`;
  } else if (payload.end) {
    url += `?endDate=${reversedash(payload.end)}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getPriorityAnalytics(
  authKey: string,
  payload: AnalyticFilter
): Promise<Analytic[]> {
  let url = "/v1/analytics/priority";
  if (payload.start && payload.end) {
    url += `?startDate=${reversedash(payload.start)}&endDate=${reversedash(
      payload.end
    )}`;
  } else if (payload.start) {
    url += `?startDate=${reversedash(payload.start)}`;
  } else if (payload.end) {
    url += `?endDate=${reversedash(payload.end)}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getCategoryAnalytics(
  authKey: string,
  payload: AnalyticFilter
): Promise<Analytic[]> {
  let url = "/v1/analytics/category";
  if (payload.start && payload.end) {
    url += `?startDate=${reversedash(payload.start)}&endDate=${reversedash(
      payload.end
    )}`;
  } else if (payload.start) {
    url += `?startDate=${reversedash(payload.start)}`;
  } else if (payload.end) {
    url += `?endDate=${reversedash(payload.end)}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getDashboardAnalytics(
  authKey: string,
  payload: DashboardFilter
): Promise<Analytic[]> {
  let url = "/v1/dashboard/status";

  if (payload.filterBy) {
    url += `?filterBy=${payload.filterBy}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getDashboardCategoryAnalytics(
  authKey: string,
  payload: DashboardFilter
): Promise<Analytic[]> {
  let url = "/v1/dashboard/categories";

  if (payload.filterBy) {
    url += `?filterBy=${payload.filterBy}`;
  }

  const response = await axios.get<Analytic[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function getDashboardStatistics(
  authKey: string,
  payload: DashboardFilter
): Promise<DashboardAnalytics[]> {
  let url = "/v1/dashboard/statistics";

  if (payload.filterBy) {
    url += `?filterBy=${payload.filterBy}`;
  }

  const response = await axios.get<DashboardAnalytics[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  getProductAnalytics,
  getPriorityAnalytics,
  getAssigneeAnalytics,
  getCategoryAnalytics,
  getDashboardAnalytics,
  getDashboardStatistics,
  getDashboardCategoryAnalytics,
};
