import categoryService from "@/services/category.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import {
  AddTicketCategory,
  CategoryTypes,
  TicketCategory,
} from "./category.types";

@Module({ namespaced: true })
class CategoryModule extends VuexModule {
  public [CategoryTypes.CATEGORIES]: TicketCategory[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addCategoryRef = 0;

  public [CategoryTypes.CATEGORY_DATA]: TicketCategory = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  public [CategoryTypes.ADD_CATEGORY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [CategoryTypes.DELETE_CATEGORY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [CategoryTypes.UPDATE_CATEGORY_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [CategoryTypes.LOADING_CATEGORIES_STATE] = false;

  @Mutation
  public [CategoryTypes.SET_ADD_CATEGORY_DIALOG_REF](ref: number): void {
    this.addCategoryRef = ref;
  }

  @Mutation
  public [CategoryTypes.SET_TICKET_CATEGORY_DATA](data: TicketCategory): void {
    this[CategoryTypes.CATEGORY_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [CategoryTypes.SET_CATEGORY_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [CategoryTypes.SET_LOADING_CATEGORY](isLoadingCard: boolean): void {
    this[CategoryTypes.LOADING_CATEGORIES_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [CategoryTypes.SET_ADD_CATEGORY_DIALOG](
    isAddingCardDialog: boolean
  ): void {
    this[CategoryTypes.ADD_CATEGORY_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [CategoryTypes.INSERT_CATEGORYS](categories: TicketCategory[]): void {
    this[CategoryTypes.CATEGORIES].splice(
      0,
      this[CategoryTypes.CATEGORIES].length
    );
    this[CategoryTypes.CATEGORIES].push(...categories);
  }

  @Mutation
  public [CategoryTypes.SET_ADD_CATEGORY_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this[CategoryTypes.ADD_CATEGORY_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [CategoryTypes.SET_ADD_CATEGORY_ERROR](addError: string): void {
    this[CategoryTypes.ADD_CATEGORY_STATE].error.errorMessage = addError;
    this[CategoryTypes.ADD_CATEGORY_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [CategoryTypes.SET_DELETE_CATEGORY_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[CategoryTypes.DELETE_CATEGORY_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [CategoryTypes.SET_DELETE_CATEGORY_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[CategoryTypes.DELETE_CATEGORY_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [CategoryTypes.SET_DELETE_CATEGORY_ERROR](deleteError: string): void {
    this[CategoryTypes.DELETE_CATEGORY_STATE].error.errorMessage = deleteError;
    this[CategoryTypes.DELETE_CATEGORY_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [CategoryTypes.SET_UPDATE_CATEGORY_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[CategoryTypes.UPDATE_CATEGORY_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [CategoryTypes.SET_UPDATE_CATEGORY_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[CategoryTypes.UPDATE_CATEGORY_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [CategoryTypes.SET_UPDATE_CATEGORY_ERROR](updateError: string): void {
    this[CategoryTypes.UPDATE_CATEGORY_STATE].error.errorMessage = updateError;
    this[CategoryTypes.UPDATE_CATEGORY_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [CategoryTypes.INSERT_CATEGORY](category: TicketCategory): void {
    const index = this[CategoryTypes.CATEGORIES]
      .map((x) => x.id)
      .indexOf(category.id);

    if (index > -1) {
      this[CategoryTypes.CATEGORIES].splice(index, 1, category);
    } else {
      this[CategoryTypes.CATEGORIES].splice(0, 0, category);
    }
  }

  // Remove Card
  @Mutation
  public [CategoryTypes.REMOVE_CATEGORY](category: TicketCategory): void {
    const index = this[CategoryTypes.CATEGORIES]
      .map((x) => x.id)
      .indexOf(category.id);

    if (index > -1) {
      this[CategoryTypes.CATEGORIES].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [CategoryTypes.LOAD_CATEGORYS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[CategoryTypes.CATEGORIES].length > 0) return;

    this.context.commit(CategoryTypes.SET_LOADING_CATEGORY, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await categoryService.getCategories(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(CategoryTypes.INSERT_CATEGORYS, response);
      // this.context.commit(CategoryTypes.SET_CATEGORY_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading categories" },
        { root: true }
      );
    } finally {
      this.context.commit(CategoryTypes.SET_LOADING_CATEGORY, false);
    }
  }
  // Add Card
  @Action
  public async [CategoryTypes.ADD_CATEGORY](
    category: AddTicketCategory
  ): Promise<void> {
    this.context.commit(CategoryTypes.SET_ADD_CATEGORY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await categoryService.addCategory(authHeader, category);
      this.context.commit(CategoryTypes.INSERT_CATEGORY, fm);
      this.context.commit(
        CategoryTypes.SET_ADD_CATEGORY_DIALOG_REF,
        this.addCategoryRef + 1
      );
      this.context.commit(CategoryTypes.SET_ADD_CATEGORY_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Category Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(CategoryTypes.SET_ADD_CATEGORY_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [CategoryTypes.DELETE_CATEGORY](): Promise<void> {
    this.context.commit(CategoryTypes.SET_DELETE_CATEGORY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await categoryService.deleteCategory(
        authHeader,
        this[CategoryTypes.CATEGORY_DATA]
      );
      this.context.commit(
        CategoryTypes.REMOVE_CATEGORY,
        this[CategoryTypes.CATEGORY_DATA]
      );
      this.context.commit(CategoryTypes.SET_DELETE_CATEGORY_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        CategoryTypes.SET_DELETE_CATEGORY_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(CategoryTypes.SET_DELETE_CATEGORY_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [CategoryTypes.UPDATE_CATEGORY](
    ticketCategory: TicketCategory
  ): Promise<void> {
    this.context.commit(CategoryTypes.SET_UPDATE_CATEGORY_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await categoryService.updateCategory(
        authHeader,
        ticketCategory
      );
      this.context.commit(CategoryTypes.INSERT_CATEGORY, fm);
      this.context.commit(CategoryTypes.SET_UPDATE_CATEGORY_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        CategoryTypes.SET_UPDATE_CATEGORY_ERROR,
        "Failed to update category"
      );
    } finally {
      this.context.commit(CategoryTypes.SET_UPDATE_CATEGORY_LOADING, false);
    }
  }
}

export default CategoryModule;
