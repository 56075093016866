import {
  AddTicketCategory,
  TicketCategory,
} from "@/store/modules/category/category.types";
import axios from "axios";
import { AddCategoryRequest } from "i-tracking-proto/js/v1/category/rpc_add_category_pb";
import { GetCategoriesRequest } from "i-tracking-proto/js/v1/category/rpc_get_categories_pb";
import { UpdateCategoryRequest } from "i-tracking-proto/js/v1/category/rpc_update_category_pb";

export async function addCategory(
  authKey: string,
  payload: AddTicketCategory
): Promise<TicketCategory> {
  const request = new AddCategoryRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.post<TicketCategory>(
    "/v1/category",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateCategory(
  authKey: string,
  payload: TicketCategory
): Promise<TicketCategory> {
  const request = new UpdateCategoryRequest();
  request.setId(payload.id);
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.patch<TicketCategory>(
    `/v1/category`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteCategory(
  authKey: string,
  payload: TicketCategory
): Promise<void> {
  await axios.delete<void>(`/v1/category/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getCategories(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<TicketCategory[]> {
  const addCategoryRequest = new GetCategoriesRequest();
  addCategoryRequest.setPage(page);
  addCategoryRequest.setLimit(offset);
  addCategoryRequest.setQuery(query);

  const response = await axios.get<TicketCategory[]>("/v1/categories", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addCategory,
  getCategories,
  updateCategory,
  deleteCategory,
};
