import {
  AddIssuePriority,
  IssuePriority,
} from "@/store/modules/priority/priority.types";
import axios from "axios";
import { AddPriorityRequest } from "i-tracking-proto/js/v1/priority/rpc_add_priority_pb";
import { GetPrioritiesRequest } from "i-tracking-proto/js/v1/priority/rpc_get_priorities_pb";
import { PrioritySLA } from "i-tracking-proto/js/v1/zone/zone_pb";

export async function addPriority(
  authKey: string,
  payload: AddIssuePriority
): Promise<IssuePriority> {
  const request = new AddPriorityRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);
  request.setPrioritySlasList(
    payload.slas.map((x) => {
      const sla = new PrioritySLA();
      sla.setName(x.priority);
      sla.setSla(x.sla);

      return sla;
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const req: any = request.toObject();
  req.zoneSlas = req["prioritySlasList"];
  delete req["prioritySlasList"];
  req.precedence = payload.precedence;

  const response = await axios.post<IssuePriority>("/v1/priority", req, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function updatePriority(
  authKey: string,
  payload: IssuePriority
): Promise<IssuePriority> {
  const response = await axios.patch<IssuePriority>(`/v1/priority`, payload, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function deletePriority(
  authKey: string,
  payload: IssuePriority
): Promise<void> {
  await axios.delete<void>(`/v1/priority/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getPriorities(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<IssuePriority[]> {
  const addPriorityRequest = new GetPrioritiesRequest();
  addPriorityRequest.setPage(page);
  addPriorityRequest.setLimit(offset);
  addPriorityRequest.setQuery(query);

  const response = await axios.get<IssuePriority[]>("/v1/priorities", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addPriority,
  getPriorities,
  updatePriority,
  deletePriority,
};
