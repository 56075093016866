import {
  ResetPasswordPayload,
  SignByUsernamePayload,
  SignResponsePayload,
} from "@/store/modules/auth/auth.types";
import axios from "axios";
import { LoginByUsernameRequest } from "i-tracking-proto/js/v1/auth/rpc_login_by_username_pb";
import { baseHeader } from "./service.config";

export async function signInWithUsername(
  payload: SignByUsernamePayload
): Promise<SignResponsePayload> {
  const request = new LoginByUsernameRequest();
  request.setUsername(payload.username);
  request.setPassword(payload.password);

  const response = await axios.post<SignResponsePayload>(
    "/v1/auth/login",
    request.toObject(),
    baseHeader
  );

  return response.data;
}

export async function signOut(authKey: string): Promise<void> {
  await axios.post<void>(
    "/v1/auth/logout",
    {},
    { headers: { "Content-Type": "application/json", Authorization: authKey } }
  );
}

export async function forgotPassword(email: string): Promise<void> {
  await axios.post<void>(
    `/v1/auth/forgot-password?email=${email}`,
    {},
    baseHeader
  );
}

export async function resetPassword(
  payload: ResetPasswordPayload
): Promise<void> {
  await axios.post<void>(`/v1/auth/reset-password`, payload, baseHeader);
}

export default {
  signInWithUsername,
  signOut,
  forgotPassword,
  resetPassword,
};
