import { DefaultState } from "@/types/types";

export type SiteZone = {
  id: number;
  name: string;
  description: string;
  precedence: number;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
  priorities: Sla[];
};

export type AddSiteZone = {
  name: string;
  description: string;
  active: boolean;
  slas: PrioritySLA[];
};

export type PrioritySLA = {
  priority: string;
  sla: number;
};

export type SLAModel = {
  name: string;
  sla: number;
};

export type ZonesState = {
  zones: SiteZone[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetTicketsResult = {
  zones: SiteZone[];
  pages: number;
};

export type Sla = {
  id: string;
  sla: number;
  name: string;
};

export enum ZoneTypes {
  MODULE = "Zones",

  // States
  ZONES = "ZONES",
  ZONE_DATA = "ZONE_DATA",
  ADD_ZONE_STATE = "ADD_ZONE_STATE",
  UPDATE_ZONE_STATE = "UPDATE_ZONE_STATE",
  DELETE_ZONE_STATE = "DELETE_ZONE_STATE",
  LOADING_ZONES_STATE = "LOADING_ZONES_STATE",

  // Actions
  LOAD_ZONES = "LOAD_ZONES",
  ADD_ZONE = "ADD_ZONE",
  DELETE_ZONE = "DELETE_ZONE",
  UPDATE_ZONE = "UPDATE_ZONE",

  // Mutations
  SET_LOADING_ZONE = "SET_LOADING_ZONE",

  INSERT_ZONE = "INSERT_ZONE",
  INSERT_ZONES = "INSERT_ZONES",
  REMOVE_ZONE = "REMOVE_ZONE",

  SET_TICKET_ZONE_DATA = "SET_TICKET_ZONE_DATA",
  SET_ZONE_PAGES = "SET_ZONE_PAGES",
  SET_ADD_ZONE_DIALOG_REF = "SET_ADD_ZONE_DIALOG_REF",

  SET_ADD_ZONE_LOADING = "SET_ADD_ZONE_LOADING",
  SET_ADD_ZONE_DIALOG = "SET_ADD_ZONE_DIALOG",
  SET_ADD_ZONE_ERROR = "SET_ADD_ZONE_ERROR",

  SET_UPDATE_ZONE_LOADING = "SET_UPDATE_ZONE_LOADING",
  SET_UPDATE_ZONE_DIALOG = "SET_UPDATE_ZONE_DIALOG",
  SET_UPDATE_ZONE_ERROR = "SET_UPDATE_ZONE_ERROR",

  SET_DELETE_ZONE_LOADING = "SET_DELETE_ZONE_LOADING",
  SET_DELETE_ZONE_DIALOG = "SET_DELETE_ZONE_DIALOG",
  SET_DELETE_ZONE_ERROR = "SET_DELETE_ZONE_ERROR",
}
