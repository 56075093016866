import roleService from "@/services/role.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import { AddSystemRole, RoleTypes, SystemRole } from "./role.types";

@Module({ namespaced: true })
class RoleModule extends VuexModule {
  public [RoleTypes.ROLES]: SystemRole[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addRoleRef = 0;

  public [RoleTypes.ROLE_DATA]: SystemRole = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
    permissions: {
      admin: false,
      viewTickets: false,
      viewAnalytics: false,
      viewAdminControl: false,
      viewReports: false,
      viewUsers: false,
      viewDepartments: false,
      viewRoles: false,
      modifyTickets: false,
      modifyUsers: false,
      modifyRoles: false,
      modifyDepartments: false,
      modifyReports: false,
    },
  };

  public [RoleTypes.ADD_ROLE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [RoleTypes.DELETE_ROLE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [RoleTypes.UPDATE_ROLE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [RoleTypes.LOADING_ROLES_STATE] = false;

  @Mutation
  public [RoleTypes.SET_ADD_ROLE_DIALOG_REF](ref: number): void {
    this.addRoleRef = ref;
  }

  @Mutation
  public [RoleTypes.SET_TICKET_ROLE_DATA](data: SystemRole): void {
    this[RoleTypes.ROLE_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [RoleTypes.SET_ROLE_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [RoleTypes.SET_LOADING_ROLE](isLoadingCard: boolean): void {
    this[RoleTypes.LOADING_ROLES_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [RoleTypes.SET_ADD_ROLE_DIALOG](isAddingCardDialog: boolean): void {
    this[RoleTypes.ADD_ROLE_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [RoleTypes.INSERT_ROLES](roles: SystemRole[]): void {
    this[RoleTypes.ROLES].splice(0, this[RoleTypes.ROLES].length);
    this[RoleTypes.ROLES].push(...roles);
  }

  @Mutation
  public [RoleTypes.SET_ADD_ROLE_LOADING](isAddingCardLoading: boolean): void {
    this[RoleTypes.ADD_ROLE_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [RoleTypes.SET_ADD_ROLE_ERROR](addError: string): void {
    this[RoleTypes.ADD_ROLE_STATE].error.errorMessage = addError;
    this[RoleTypes.ADD_ROLE_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [RoleTypes.SET_DELETE_ROLE_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[RoleTypes.DELETE_ROLE_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [RoleTypes.SET_DELETE_ROLE_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[RoleTypes.DELETE_ROLE_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [RoleTypes.SET_DELETE_ROLE_ERROR](deleteError: string): void {
    this[RoleTypes.DELETE_ROLE_STATE].error.errorMessage = deleteError;
    this[RoleTypes.DELETE_ROLE_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [RoleTypes.SET_UPDATE_ROLE_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[RoleTypes.UPDATE_ROLE_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [RoleTypes.SET_UPDATE_ROLE_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[RoleTypes.UPDATE_ROLE_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [RoleTypes.SET_UPDATE_ROLE_ERROR](updateError: string): void {
    this[RoleTypes.UPDATE_ROLE_STATE].error.errorMessage = updateError;
    this[RoleTypes.UPDATE_ROLE_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [RoleTypes.INSERT_ROLE](role: SystemRole): void {
    const index = this[RoleTypes.ROLES].map((x) => x.id).indexOf(role.id);

    if (index > -1) {
      this[RoleTypes.ROLES].splice(index, 1, role);
    } else {
      this[RoleTypes.ROLES].splice(0, 0, role);
    }
  }

  // Remove Card
  @Mutation
  public [RoleTypes.REMOVE_ROLE](role: SystemRole): void {
    const index = this[RoleTypes.ROLES].map((x) => x.id).indexOf(role.id);

    if (index > -1) {
      this[RoleTypes.ROLES].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [RoleTypes.LOAD_ROLES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[RoleTypes.ROLES].length > 0) return;

    this.context.commit(RoleTypes.SET_LOADING_ROLE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await roleService.getRoles(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(RoleTypes.INSERT_ROLES, response);
      // this.context.commit(RoleTypes.SET_ROLE_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading roles" },
        { root: true }
      );
    } finally {
      this.context.commit(RoleTypes.SET_LOADING_ROLE, false);
    }
  }
  // Add Card
  @Action
  public async [RoleTypes.ADD_ROLE](role: AddSystemRole): Promise<void> {
    this.context.commit(RoleTypes.SET_ADD_ROLE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await roleService.addRole(authHeader, role);
      this.context.commit(RoleTypes.INSERT_ROLE, fm);
      this.context.commit(
        RoleTypes.SET_ADD_ROLE_DIALOG_REF,
        this.addRoleRef + 1
      );
      this.context.commit(RoleTypes.SET_ADD_ROLE_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Role Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(RoleTypes.SET_ADD_ROLE_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [RoleTypes.DELETE_ROLE](): Promise<void> {
    this.context.commit(RoleTypes.SET_DELETE_ROLE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await roleService.deleteRole(authHeader, this[RoleTypes.ROLE_DATA]);
      this.context.commit(RoleTypes.REMOVE_ROLE, this[RoleTypes.ROLE_DATA]);
      this.context.commit(RoleTypes.SET_DELETE_ROLE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RoleTypes.SET_DELETE_ROLE_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(RoleTypes.SET_DELETE_ROLE_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [RoleTypes.UPDATE_ROLE](systemRole: SystemRole): Promise<void> {
    this.context.commit(RoleTypes.SET_UPDATE_ROLE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await roleService.updateRole(authHeader, systemRole);
      this.context.commit(RoleTypes.INSERT_ROLE, fm);
      this.context.commit(RoleTypes.SET_UPDATE_ROLE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RoleTypes.SET_UPDATE_ROLE_ERROR,
        "Failed to update role"
      );
    } finally {
      this.context.commit(RoleTypes.SET_UPDATE_ROLE_LOADING, false);
    }
  }
}

export default RoleModule;
