import zoneService from "@/services/zone.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import { AddSiteZone, ZoneTypes, SiteZone } from "./zone.types";

@Module({ namespaced: true })
class ZoneModule extends VuexModule {
  public [ZoneTypes.ZONES]: SiteZone[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addZoneRef = 0;

  public [ZoneTypes.ZONE_DATA]: SiteZone = {
    id: -1,
    name: "",
    description: "",
    active: false,
    precedence: 0,
    createdAt: new Date(),
    priorities: [],
  };

  public [ZoneTypes.ADD_ZONE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ZoneTypes.DELETE_ZONE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ZoneTypes.UPDATE_ZONE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ZoneTypes.LOADING_ZONES_STATE] = false;

  @Mutation
  public [ZoneTypes.SET_ADD_ZONE_DIALOG_REF](ref: number): void {
    this.addZoneRef = ref;
  }

  @Mutation
  public [ZoneTypes.SET_TICKET_ZONE_DATA](data: SiteZone): void {
    this[ZoneTypes.ZONE_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [ZoneTypes.SET_ZONE_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [ZoneTypes.SET_LOADING_ZONE](isLoadingCard: boolean): void {
    this[ZoneTypes.LOADING_ZONES_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [ZoneTypes.SET_ADD_ZONE_DIALOG](isAddingCardDialog: boolean): void {
    this[ZoneTypes.ADD_ZONE_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [ZoneTypes.INSERT_ZONES](zones: SiteZone[]): void {
    this[ZoneTypes.ZONES].splice(0, this[ZoneTypes.ZONES].length);
    this[ZoneTypes.ZONES].push(...zones);
  }

  @Mutation
  public [ZoneTypes.SET_ADD_ZONE_LOADING](isAddingCardLoading: boolean): void {
    this[ZoneTypes.ADD_ZONE_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [ZoneTypes.SET_ADD_ZONE_ERROR](addError: string): void {
    this[ZoneTypes.ADD_ZONE_STATE].error.errorMessage = addError;
    this[ZoneTypes.ADD_ZONE_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [ZoneTypes.SET_DELETE_ZONE_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[ZoneTypes.DELETE_ZONE_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [ZoneTypes.SET_DELETE_ZONE_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[ZoneTypes.DELETE_ZONE_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [ZoneTypes.SET_DELETE_ZONE_ERROR](deleteError: string): void {
    this[ZoneTypes.DELETE_ZONE_STATE].error.errorMessage = deleteError;
    this[ZoneTypes.DELETE_ZONE_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [ZoneTypes.SET_UPDATE_ZONE_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[ZoneTypes.UPDATE_ZONE_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [ZoneTypes.SET_UPDATE_ZONE_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[ZoneTypes.UPDATE_ZONE_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [ZoneTypes.SET_UPDATE_ZONE_ERROR](updateError: string): void {
    this[ZoneTypes.UPDATE_ZONE_STATE].error.errorMessage = updateError;
    this[ZoneTypes.UPDATE_ZONE_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [ZoneTypes.INSERT_ZONE](zone: SiteZone): void {
    const index = this[ZoneTypes.ZONES].map((x) => x.id).indexOf(zone.id);

    if (index > -1) {
      this[ZoneTypes.ZONES].splice(index, 1, zone);
    } else {
      this[ZoneTypes.ZONES].splice(0, 0, zone);
    }
  }

  // Remove Card
  @Mutation
  public [ZoneTypes.REMOVE_ZONE](zone: SiteZone): void {
    const index = this[ZoneTypes.ZONES].map((x) => x.id).indexOf(zone.id);

    if (index > -1) {
      this[ZoneTypes.ZONES].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [ZoneTypes.LOAD_ZONES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[ZoneTypes.ZONES].length > 0) return;

    this.context.commit(ZoneTypes.SET_LOADING_ZONE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await zoneService.getZones(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(ZoneTypes.INSERT_ZONES, response);
      // this.context.commit(ZoneTypes.SET_ZONE_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading zones" },
        { root: true }
      );
    } finally {
      this.context.commit(ZoneTypes.SET_LOADING_ZONE, false);
    }
  }
  // Add Card
  @Action
  public async [ZoneTypes.ADD_ZONE](zone: AddSiteZone): Promise<void> {
    this.context.commit(ZoneTypes.SET_ADD_ZONE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await zoneService.addZone(authHeader, zone);
      this.context.commit(ZoneTypes.INSERT_ZONE, fm);
      this.context.commit(
        ZoneTypes.SET_ADD_ZONE_DIALOG_REF,
        this.addZoneRef + 1
      );
      this.context.commit(ZoneTypes.SET_ADD_ZONE_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Zone Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(ZoneTypes.SET_ADD_ZONE_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [ZoneTypes.DELETE_ZONE](): Promise<void> {
    this.context.commit(ZoneTypes.SET_DELETE_ZONE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await zoneService.deleteZone(authHeader, this[ZoneTypes.ZONE_DATA]);
      this.context.commit(ZoneTypes.REMOVE_ZONE, this[ZoneTypes.ZONE_DATA]);
      this.context.commit(ZoneTypes.SET_DELETE_ZONE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        ZoneTypes.SET_DELETE_ZONE_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(ZoneTypes.SET_DELETE_ZONE_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [ZoneTypes.UPDATE_ZONE](ticketZone: SiteZone): Promise<void> {
    this.context.commit(ZoneTypes.SET_UPDATE_ZONE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await zoneService.updateZone(authHeader, ticketZone);
      this.context.commit(ZoneTypes.INSERT_ZONE, fm);
      this.context.commit(ZoneTypes.SET_UPDATE_ZONE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        ZoneTypes.SET_UPDATE_ZONE_ERROR,
        "Failed to update zone"
      );
    } finally {
      this.context.commit(ZoneTypes.SET_UPDATE_ZONE_LOADING, false);
    }
  }
}

export default ZoneModule;
