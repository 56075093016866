export type Analytic = {
  name: string;
  count: number;
};

export type AnalyticFilter = {
  start?: string;
  end?: string;
  force?: boolean;
};

export type DashboardFilter = {
  filterBy?: string;
  force?: boolean;
};

export type AnalyticsState = {
  analytics: Analytic[];
  loading: boolean;
};

export type DashboardAnalyticsState = {
  analytics: DashboardAnalytics;
  loading: boolean;
};

export type DashboardAnalytics = {
  statistics: Analytic[];
  total: number;
  percentClosed: number;
};

export enum AnalyticsTypes {
  MODULE = "Analytics",

  PRODUCT_ANALYTICS = "PRODUCT_ANALYTICS",
  LOAD_PRODUCT_ANALYTICS = "LOAD_PRODUCT_ANALYTICS",
  SET_PRODUCT_ANALYTICS = "SET_PRODUCT_ANALYTICS",
  SET_LOADING_PRODUCT_ANALYTICS = "SET_LOADING_PRODUCT_ANALYTICS",
  SET_LOADING_PRODUCT_ANALYTICS_ERROR = "SET_LOADING_PRODUCT_ANALYTICS_ERROR",

  DASHBOARD_ANALYTICS = "DASHBOARD_ANALYTICS",
  LOAD_DASHBOARD_ANALYTICS = "LOAD_DASHBOARD_ANALYTICS",
  SET_DASHBOARD_ANALYTICS = "SET_DASHBOARD_ANALYTICS",
  SET_LOADING_DASHBOARD_ANALYTICS = "SET_LOADING_DASHBOARD_ANALYTICS",
  SET_LOADING_DASHBOARD_ANALYTICS_ERROR = "SET_LOADING_DASHBOARD_ANALYTICS_ERROR",

  CATEGORY_ANALYTICS = "CATEGORY_ANALYTICS",
  LOAD_CATEGORY_ANALYTICS = "LOAD_CATEGORY_ANALYTICS",
  SET_CATEGORY_ANALYTICS = "SET_CATEGORY_ANALYTICS",
  SET_LOADING_CATEGORY_ANALYTICS = "SET_LOADING_CATEGORY_ANALYTICS",
  SET_LOADING_CATEGORY_ANALYTICS_ERROR = "SET_LOADING_CATEGORY_ANALYTICS_ERROR",

  PRIORITY_ANALYTICS = "PRIORITY_ANALYTICS",
  LOAD_PRIORITY_ANALYTICS = "LOAD_PRIORITY_ANALYTICS",
  SET_PRIORITY_ANALYTICS = "SET_PRIORITY_ANALYTICS",
  SET_LOADING_PRIORITY_ANALYTICS = "SET_LOADING_PRIORITY_ANALYTICS",
  SET_LOADING_PRIORITY_ANALYTICS_ERROR = "SET_LOADING_PRIORITY_ANALYTICS_ERROR",

  ASSIGNEE_ANALYTICS = "ASSIGNEE_ANALYTICS",
  LOAD_ASSIGNEE_ANALYTICS = "LOAD_ASSIGNEE_ANALYTICS",
  SET_ASSIGNEE_ANALYTICS = "SET_ASSIGNEE_ANALYTICS",
  SET_LOADING_ASSIGNEE_ANALYTICS = "SET_LOADING_ASSIGNEE_ANALYTICS",
  SET_LOADING_ASSIGNEE_ANALYTICS_ERROR = "SET_LOADING_ASSIGNEE_ANALYTICS_ERROR",

  DASHBOARD_STATUS = "DASHBOARD_STATUS",
  LOAD_DASHBOARD_STATUS = "LOAD_DASHBOARD_STATUS",
  SET_DASHBOARD_STATUS = "SET_DASHBOARD_STATUS",
  SET_LOADING_DASHBOARD_STATUS = "SET_LOADING_DASHBOARD_STATUS",
  SET_LOADING_DASHBOARD_STATUS_ERROR = "SET_LOADING_DASHBOARD_STATUS_ERROR",

  DASHBOARD_CATEGORIES = "DASHBOARD_CATEGORIES",
  LOAD_DASHBOARD_CATEGORIES = "LOAD_DASHBOARD_CATEGORIES",
  SET_DASHBOARD_CATEGORIES = "SET_DASHBOARD_CATEGORIES",
  SET_LOADING_DASHBOARD_CATEGORIES = "SET_LOADING_DASHBOARD_CATEGORIES",
  SET_LOADING_DASHBOARD_CATEGORIES_ERROR = "SET_LOADING_DASHBOARD_CATEGORIES_ERROR",

  DASHBOARD_FILTER_TYPES = "DASHBOARD_FILTER_TYPES",
  CURRENT_DASHBOARD_FILTER = "CURRENT_DASHBOARD_FILTER",

  DASHBOARD_FILTER = "DASHBOARD_FILTER",
  SET_DASHBOARD_FILTER = "SET_DASHBOARD_FILTER",

  ANALYTICS_FILTER = "ANALYTICS_FILTER",
  SET_ANALYTICS_FILTER = "SET_ANALYTICS_FILTER",
}
