import {
  AddUserDepartment,
  UserDepartment,
} from "@/store/modules/department/department.types";
import axios from "axios";
import { AddDepartmentRequest } from "i-tracking-proto/js/v1/department/rpc_add_department_pb";
import { GetDepartmentsRequest } from "i-tracking-proto/js/v1/department/rpc_get_departments_pb";
import { UpdateDepartmentRequest } from "i-tracking-proto/js/v1/department/rpc_update_department_pb";

export async function addDepartment(
  authKey: string,
  payload: AddUserDepartment
): Promise<UserDepartment> {
  const request = new AddDepartmentRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.post<UserDepartment>(
    "/v1/department",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateDepartment(
  authKey: string,
  payload: UserDepartment
): Promise<UserDepartment> {
  const request = new UpdateDepartmentRequest();
  request.setId(payload.id);
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.patch<UserDepartment>(
    `/v1/department`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteDepartment(
  authKey: string,
  payload: UserDepartment
): Promise<void> {
  await axios.delete<void>(`/v1/department/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getDepartments(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<UserDepartment[]> {
  const addDepartmentRequest = new GetDepartmentsRequest();
  addDepartmentRequest.setPage(page);
  addDepartmentRequest.setLimit(offset);
  addDepartmentRequest.setQuery(query);

  const response = await axios.get<UserDepartment[]>("/v1/departments", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addDepartment,
  getDepartments,
  updateDepartment,
  deleteDepartment,
};
