import { UpdatePasswordPayload } from "@/store/modules/auth/auth.types";
import { AddSystemUser, SystemUser } from "@/store/modules/user/user.types";
import axios from "axios";
import { AddUserRequest } from "i-tracking-proto/js/v1/user/rpc_add_user_pb";
import { GetUsersRequest } from "i-tracking-proto/js/v1/user/rpc_get_users_pb";
import { UpdateUserRequest } from "i-tracking-proto/js/v1/user/rpc_update_user_pb";

export async function addUser(
  authKey: string,
  payload: AddSystemUser
): Promise<SystemUser> {
  const request = new AddUserRequest();
  request.setFirstname(payload.firstname);
  request.setLastname(payload.lastname);
  request.setUsername(payload.username);
  request.setEmail(payload.email);
  request.setPhone(payload.phone);
  request.setPassword(payload.password);
  if (payload.department) request.setDepartment(payload.department);
  if (payload.role) request.setRole(payload.role);
  request.setActive(payload.active);

  const response = await axios.post<SystemUser>(
    "/v1/user",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  const data = response.data;
  data.name = `${data.firstname} ${data.lastname}`;
  return data;
}

export async function updateUser(
  authKey: string,
  payload: SystemUser
): Promise<SystemUser> {
  const request = new UpdateUserRequest();
  request.setId(payload.id);
  request.setFirstname(payload.firstname);
  request.setLastname(payload.lastname);
  request.setUsername(payload.username);
  request.setEmail(payload.email);
  request.setPhone(payload.phone);
  request.setDepartment(payload.department);
  request.setRole(payload.role);
  request.setActive(payload.active);

  const response = await axios.patch<SystemUser>(
    `/v1/user`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );
  const data = response.data;
  data.name = `${data.firstname} ${data.lastname}`;
  return data;
}

export async function getUserProfile(authKey: string): Promise<SystemUser> {
  const response = await axios.get<SystemUser>(`/v1/user/profile`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  const data = response.data;
  data.name = `${data.firstname} ${data.lastname}`;
  return data;
}

export async function deleteUser(
  authKey: string,
  payload: SystemUser
): Promise<void> {
  await axios.delete<void>(`/v1/user/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getUsers(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<SystemUser[]> {
  const addUserRequest = new GetUsersRequest();
  addUserRequest.setPage(page);
  addUserRequest.setLimit(offset);
  addUserRequest.setQuery(query);

  const response = await axios.get<SystemUser[]>("/v1/users", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data.map((user) => {
    user.name = `${user.firstname} ${user.lastname}`;
    return user;
  });
}

export async function updatePassword(
  authKey: string,
  payload: UpdatePasswordPayload
): Promise<void> {
  await axios.post<void>("/v1/user/update-password", payload, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export default {
  addUser,
  getUsers,
  updateUser,
  deleteUser,
  getUserProfile,
  updatePassword,
};
