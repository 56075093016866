import {
  AddSystemProduct,
  SystemProduct,
} from "@/store/modules/product/product.types";
import axios from "axios";
import { AddProductRequest } from "i-tracking-proto/js/v1/product/rpc_add_product_pb";
import { GetProductsRequest } from "i-tracking-proto/js/v1/product/rpc_get_products_pb";
import { UpdateProductRequest } from "i-tracking-proto/js/v1/product/rpc_update_product_pb";

export async function addProduct(
  authKey: string,
  payload: AddSystemProduct
): Promise<SystemProduct> {
  const request = new AddProductRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.post<SystemProduct>(
    "/v1/product",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateProduct(
  authKey: string,
  payload: SystemProduct
): Promise<SystemProduct> {
  const request = new UpdateProductRequest();
  request.setId(payload.id);
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const response = await axios.patch<SystemProduct>(
    `/v1/product`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteProduct(
  authKey: string,
  payload: SystemProduct
): Promise<void> {
  await axios.delete<void>(`/v1/product/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getProducts(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<SystemProduct[]> {
  const addProductRequest = new GetProductsRequest();
  addProductRequest.setPage(page);
  addProductRequest.setLimit(offset);
  addProductRequest.setQuery(query);

  const response = await axios.get<SystemProduct[]>("/v1/products", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addProduct,
  getProducts,
  updateProduct,
  deleteProduct,
};
