
import { Component, Mixins } from "vue-property-decorator";
import { State } from "vuex-class";
import { UpdateMixin } from "./mixins/updates";
import { SnackbarState } from "./store/modules/root.state";
import { RootTypes } from "./store/modules/root.types";

@Component
export default class App extends Mixins(UpdateMixin) {
  @State(RootTypes.snackbar)
  public snackBar!: SnackbarState;

  @State(RootTypes.loading)
  public loading!: boolean;
}
