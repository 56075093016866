import { AddSiteStation, SiteStation } from "@/store/modules/site/site.types";
import axios from "axios";
import { AddSiteRequest } from "i-tracking-proto/js/v1/site/rpc_add_site_pb";
import { GetSitesRequest } from "i-tracking-proto/js/v1/site/rpc_get_sites_pb";
import { UpdateSiteRequest } from "i-tracking-proto/js/v1/site/rpc_update_site_pb";

export async function addSite(
  authKey: string,
  payload: AddSiteStation
): Promise<SiteStation> {
  const request = new AddSiteRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);
  request.setRegion(payload.region);
  request.setZone(payload.zone);

  const response = await axios.post<SiteStation>(
    "/v1/site",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateSite(
  authKey: string,
  payload: SiteStation
): Promise<SiteStation> {
  const request = new UpdateSiteRequest();
  request.setId(payload.id);
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);
  request.setRegion(payload.region);
  request.setZone(payload.zone);

  const response = await axios.patch<SiteStation>(
    `/v1/site`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteSite(
  authKey: string,
  payload: SiteStation
): Promise<void> {
  await axios.delete<void>(`/v1/site/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getSites(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<SiteStation[]> {
  const addSiteRequest = new GetSitesRequest();
  addSiteRequest.setPage(page);
  addSiteRequest.setLimit(offset);
  addSiteRequest.setQuery(query);

  const response = await axios.get<SiteStation[]>("/v1/sites", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addSite,
  getSites,
  updateSite,
  deleteSite,
};
