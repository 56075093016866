import { UserProfile } from "i-tracking-proto/js/v1/user/user_pb";

export type AuthState = {
  authenticated: boolean;
  signIn: {
    loading: boolean;
  };
};

export type SignInState = {
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
};

export type SignInPayload = {
  email: string;
  password: string;
};

export type AuthVerifyPayload = {
  code: string;
  secret: string;
};

export type SignByUsernamePayload = {
  username: string;
  password: string;
};

export type SignResponsePayload = {
  accessToken: string;
  accessTokenExpiresAt: Date;
  tokenType: string;
  user: UserDetails;
};

export type UserDetails = {
  name: string;
  username: string;
  active: boolean;
  permissions: string;
  createdAt: Date;
};

export type RefreshTokenPayload = {
  refresh_token: string;
};

export type TokenPayload = {
  token: string;
  expiry: Date;
};

export type JsUserProfile = {
  name: string;
  username: string;
  active: boolean;
  permissions: string;
  created_at: Date;
};

export type ResetPasswordPayload = {
  resetToken: string;
  newPassword: string;
};

export type UpdatePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};

export enum SignInTypes {
  MODULE = "Auth",
  SET_AUTHENTICATED = "SET_AUTHENTICATED",
  SET_LAST_PATH = "SET_LAST_PATH",
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  CLEAR_ACCESS_TOKEN = "CLEAR_ACCESS_TOKEN",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  CLEAR_REFRESH_TOKEN = "CLEAR_REFRESH_TOKEN",
  SET_USER_DETAILS = "SET_USER_DETAILS",
  CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS",

  CLEAR_AUTH = "CLEAR_AUTH",
  SIGN_IN = "SIGN_IN",
  VERIFY_SIGN_IN = "VERIFY_SIGN_IN",
  SET_SIGN_IN_LOADING = "SET_SIGN_IN_LOADING",
  SET_SIGN_IN_ERROR = "SET_SIGN_IN_ERROR",
  SET_SIGN_IN_SECRET = "SET_SIGN_IN_SECRET",
  CLEAR_SIGN_IN_ERROR = "CLEAR_SIGN_IN_ERROR",

  SIGN_OUT = "SIGN_OUT",
  SET_SIGN_OUT_LOADING = "SET_SIGN_OUT_LOADING",
  SET_SIGN_OUT_ERROR = "SET_SIGN_OUT_ERROR",
  CLEAR_SIGN_OUT_ERROR = "CLEAR_SIGN_OUT_ERROR",

  FORGOT_PASSWORD_STATE = "FORGOT_PASSWORD_STATE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SET_FORGOT_PASSWORD_LOADING = "SET_FORGOT_PASSWORD_LOADING",
  SET_FORGOT_PASSWORD_DIALOG = "SET_FORGOT_PASSWORD_DIALOG",
  SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR",

  RESET_PASSWORD_STATE = "RESET_PASSWORD_STATE",
  RESET_PASSWORD = "RESET_PASSWORD",
  SET_RESET_PASSWORD_LOADING = "SET_RESET_PASSWORD_LOADING",
  SET_RESET_PASSWORD_DIALOG = "SET_RESET_PASSWORD_DIALOG",
  SET_RESET_PASSWORD_ERROR = "SET_RESET_PASSWORD_ERROR",

  UPDATE_PASSWORD_STATE = "UPDATE_PASSWORD_STATE",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  SET_UPDATE_PASSWORD_LOADING = "SET_UPDATE_PASSWORD_LOADING",
  SET_UPDATE_PASSWORD_DIALOG = "SET_UPDATE_PASSWORD_DIALOG",
  SET_UPDATE_PASSWORD_ERROR = "SET_UPDATE_PASSWORD_ERROR",

  REFRESH_TOKEN = "REFRESH_TOKEN",
  SET_REFRESH_TOKEN_LOADING = "SET_REFRESH_TOKEN_LOADING",
  SET_REFRESH_TOKEN_ERROR = "SET_REFRESH_TOKEN_ERROR",
  CLEAR_REFRESH_TOKEN_ERROR = "CLEAR_REFRESH_TOKEN_ERROR",
}

export function pbUserProfileToJsUserProfile(
  profile: UserProfile
): JsUserProfile {
  return {
    name: profile.getName(),
    username: profile.getUsername(),
    active: profile.getActive(),
    permissions: profile.getPermissions(),
    created_at: profile.getCreatedAt()?.toDate() ?? new Date(),
  };
}
