import siteService from "@/services/site.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import { AddSiteStation, SiteTypes, SiteStation } from "./site.types";

@Module({ namespaced: true })
class SiteModule extends VuexModule {
  public [SiteTypes.SITES]: SiteStation[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addSiteRef = 0;

  public [SiteTypes.SITE_DATA]: SiteStation = {
    id: -1,
    name: "",
    region: "",
    description: "",
    zone: "",
    active: false,
    createdAt: new Date(),
  };

  public [SiteTypes.ADD_SITE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [SiteTypes.DELETE_SITE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [SiteTypes.UPDATE_SITE_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [SiteTypes.LOADING_SITES_STATE] = false;

  @Mutation
  public [SiteTypes.SET_ADD_SITE_DIALOG_REF](ref: number): void {
    this.addSiteRef = ref;
  }

  @Mutation
  public [SiteTypes.SET_TICKET_SITE_DATA](data: SiteStation): void {
    this[SiteTypes.SITE_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [SiteTypes.SET_SITE_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [SiteTypes.SET_LOADING_SITE](isLoadingCard: boolean): void {
    this[SiteTypes.LOADING_SITES_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [SiteTypes.SET_ADD_SITE_DIALOG](isAddingCardDialog: boolean): void {
    this[SiteTypes.ADD_SITE_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [SiteTypes.INSERT_SITES](sites: SiteStation[]): void {
    this[SiteTypes.SITES].splice(0, this[SiteTypes.SITES].length);
    this[SiteTypes.SITES].push(...sites);
  }

  @Mutation
  public [SiteTypes.SET_ADD_SITE_LOADING](isAddingCardLoading: boolean): void {
    this[SiteTypes.ADD_SITE_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [SiteTypes.SET_ADD_SITE_ERROR](addError: string): void {
    this[SiteTypes.ADD_SITE_STATE].error.errorMessage = addError;
    this[SiteTypes.ADD_SITE_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [SiteTypes.SET_DELETE_SITE_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[SiteTypes.DELETE_SITE_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [SiteTypes.SET_DELETE_SITE_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[SiteTypes.DELETE_SITE_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [SiteTypes.SET_DELETE_SITE_ERROR](deleteError: string): void {
    this[SiteTypes.DELETE_SITE_STATE].error.errorMessage = deleteError;
    this[SiteTypes.DELETE_SITE_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [SiteTypes.SET_UPDATE_SITE_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[SiteTypes.UPDATE_SITE_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [SiteTypes.SET_UPDATE_SITE_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[SiteTypes.UPDATE_SITE_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [SiteTypes.SET_UPDATE_SITE_ERROR](updateError: string): void {
    this[SiteTypes.UPDATE_SITE_STATE].error.errorMessage = updateError;
    this[SiteTypes.UPDATE_SITE_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [SiteTypes.INSERT_SITE](site: SiteStation): void {
    const index = this[SiteTypes.SITES].map((x) => x.id).indexOf(site.id);

    if (index > -1) {
      this[SiteTypes.SITES].splice(index, 1, site);
    } else {
      this[SiteTypes.SITES].splice(0, 0, site);
    }
  }

  // Remove Card
  @Mutation
  public [SiteTypes.REMOVE_SITE](site: SiteStation): void {
    const index = this[SiteTypes.SITES].map((x) => x.id).indexOf(site.id);

    if (index > -1) {
      this[SiteTypes.SITES].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [SiteTypes.LOAD_SITES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[SiteTypes.SITES].length > 0) return;

    this.context.commit(SiteTypes.SET_LOADING_SITE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await siteService.getSites(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(SiteTypes.INSERT_SITES, response);
      // this.context.commit(SiteTypes.SET_SITE_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading sites" },
        { root: true }
      );
    } finally {
      this.context.commit(SiteTypes.SET_LOADING_SITE, false);
    }
  }
  // Add Card
  @Action
  public async [SiteTypes.ADD_SITE](site: AddSiteStation): Promise<void> {
    this.context.commit(SiteTypes.SET_ADD_SITE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await siteService.addSite(authHeader, site);
      this.context.commit(SiteTypes.INSERT_SITE, fm);
      this.context.commit(
        SiteTypes.SET_ADD_SITE_DIALOG_REF,
        this.addSiteRef + 1
      );
      this.context.commit(SiteTypes.SET_ADD_SITE_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Site Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(SiteTypes.SET_ADD_SITE_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [SiteTypes.DELETE_SITE](): Promise<void> {
    this.context.commit(SiteTypes.SET_DELETE_SITE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await siteService.deleteSite(authHeader, this[SiteTypes.SITE_DATA]);
      this.context.commit(SiteTypes.REMOVE_SITE, this[SiteTypes.SITE_DATA]);
      this.context.commit(SiteTypes.SET_DELETE_SITE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        SiteTypes.SET_DELETE_SITE_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(SiteTypes.SET_DELETE_SITE_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [SiteTypes.UPDATE_SITE](ticketSite: SiteStation): Promise<void> {
    this.context.commit(SiteTypes.SET_UPDATE_SITE_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await siteService.updateSite(authHeader, ticketSite);
      this.context.commit(SiteTypes.INSERT_SITE, fm);
      this.context.commit(SiteTypes.SET_UPDATE_SITE_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        SiteTypes.SET_UPDATE_SITE_ERROR,
        "Failed to update site"
      );
    } finally {
      this.context.commit(SiteTypes.SET_UPDATE_SITE_LOADING, false);
    }
  }
}

export default SiteModule;
