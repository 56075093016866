import departmentService from "@/services/department.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import {
  AddUserDepartment,
  DepartmentTypes,
  UserDepartment,
} from "./department.types";

@Module({ namespaced: true })
class DepartmentModule extends VuexModule {
  public [DepartmentTypes.DEPARTMENTS]: UserDepartment[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addDepartmentRef = 0;

  public [DepartmentTypes.DEPARTMENT_DATA]: UserDepartment = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  public [DepartmentTypes.ADD_DEPARTMENT_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [DepartmentTypes.DELETE_DEPARTMENT_STATE]: DefaultState =
    Object.assign({}, this.defaultState);

  public [DepartmentTypes.UPDATE_DEPARTMENT_STATE]: DefaultState =
    Object.assign({}, this.defaultState);

  public [DepartmentTypes.LOADING_DEPARTMENTS_STATE] = false;

  @Mutation
  public [DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG_REF](ref: number): void {
    this.addDepartmentRef = ref;
  }

  @Mutation
  public [DepartmentTypes.SET_TICKET_DEPARTMENT_DATA](
    data: UserDepartment
  ): void {
    this[DepartmentTypes.DEPARTMENT_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [DepartmentTypes.SET_DEPARTMENT_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [DepartmentTypes.SET_LOADING_DEPARTMENT](
    isLoadingCard: boolean
  ): void {
    this[DepartmentTypes.LOADING_DEPARTMENTS_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG](
    isAddingCardDialog: boolean
  ): void {
    this[DepartmentTypes.ADD_DEPARTMENT_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [DepartmentTypes.INSERT_DEPARTMENTS](
    departments: UserDepartment[]
  ): void {
    this[DepartmentTypes.DEPARTMENTS].splice(
      0,
      this[DepartmentTypes.DEPARTMENTS].length
    );
    this[DepartmentTypes.DEPARTMENTS].push(...departments);
  }

  @Mutation
  public [DepartmentTypes.SET_ADD_DEPARTMENT_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this[DepartmentTypes.ADD_DEPARTMENT_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [DepartmentTypes.SET_ADD_DEPARTMENT_ERROR](addError: string): void {
    this[DepartmentTypes.ADD_DEPARTMENT_STATE].error.errorMessage = addError;
    this[DepartmentTypes.ADD_DEPARTMENT_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [DepartmentTypes.SET_DELETE_DEPARTMENT_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[DepartmentTypes.DELETE_DEPARTMENT_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [DepartmentTypes.SET_DELETE_DEPARTMENT_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[DepartmentTypes.DELETE_DEPARTMENT_STATE].loading =
      isDeletingCardLoading;
  }

  @Mutation
  public [DepartmentTypes.SET_DELETE_DEPARTMENT_ERROR](
    deleteError: string
  ): void {
    this[DepartmentTypes.DELETE_DEPARTMENT_STATE].error.errorMessage =
      deleteError;
    this[DepartmentTypes.DELETE_DEPARTMENT_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [DepartmentTypes.SET_UPDATE_DEPARTMENT_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[DepartmentTypes.UPDATE_DEPARTMENT_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [DepartmentTypes.SET_UPDATE_DEPARTMENT_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[DepartmentTypes.UPDATE_DEPARTMENT_STATE].loading =
      isUpdatingCardLoading;
  }

  @Mutation
  public [DepartmentTypes.SET_UPDATE_DEPARTMENT_ERROR](
    updateError: string
  ): void {
    this[DepartmentTypes.UPDATE_DEPARTMENT_STATE].error.errorMessage =
      updateError;
    this[DepartmentTypes.UPDATE_DEPARTMENT_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [DepartmentTypes.INSERT_DEPARTMENT](department: UserDepartment): void {
    const index = this[DepartmentTypes.DEPARTMENTS]
      .map((x) => x.id)
      .indexOf(department.id);

    if (index > -1) {
      this[DepartmentTypes.DEPARTMENTS].splice(index, 1, department);
    } else {
      this[DepartmentTypes.DEPARTMENTS].splice(0, 0, department);
    }
  }

  // Remove Card
  @Mutation
  public [DepartmentTypes.REMOVE_DEPARTMENT](department: UserDepartment): void {
    const index = this[DepartmentTypes.DEPARTMENTS]
      .map((x) => x.id)
      .indexOf(department.id);

    if (index > -1) {
      this[DepartmentTypes.DEPARTMENTS].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [DepartmentTypes.LOAD_DEPARTMENTS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[DepartmentTypes.DEPARTMENTS].length > 0) return;

    this.context.commit(DepartmentTypes.SET_LOADING_DEPARTMENT, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await departmentService.getDepartments(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(DepartmentTypes.INSERT_DEPARTMENTS, response);
      // this.context.commit(DepartmentTypes.SET_DEPARTMENT_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading departments" },
        { root: true }
      );
    } finally {
      this.context.commit(DepartmentTypes.SET_LOADING_DEPARTMENT, false);
    }
  }
  // Add Card
  @Action
  public async [DepartmentTypes.ADD_DEPARTMENT](
    department: AddUserDepartment
  ): Promise<void> {
    this.context.commit(DepartmentTypes.SET_ADD_DEPARTMENT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await departmentService.addDepartment(authHeader, department);
      this.context.commit(DepartmentTypes.INSERT_DEPARTMENT, fm);
      this.context.commit(
        DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG_REF,
        this.addDepartmentRef + 1
      );
      this.context.commit(DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Department Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(DepartmentTypes.SET_ADD_DEPARTMENT_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [DepartmentTypes.DELETE_DEPARTMENT](): Promise<void> {
    this.context.commit(DepartmentTypes.SET_DELETE_DEPARTMENT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await departmentService.deleteDepartment(
        authHeader,
        this[DepartmentTypes.DEPARTMENT_DATA]
      );
      this.context.commit(
        DepartmentTypes.REMOVE_DEPARTMENT,
        this[DepartmentTypes.DEPARTMENT_DATA]
      );
      this.context.commit(DepartmentTypes.SET_DELETE_DEPARTMENT_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        DepartmentTypes.SET_DELETE_DEPARTMENT_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(DepartmentTypes.SET_DELETE_DEPARTMENT_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [DepartmentTypes.UPDATE_DEPARTMENT](
    ticketDepartment: UserDepartment
  ): Promise<void> {
    this.context.commit(DepartmentTypes.SET_UPDATE_DEPARTMENT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await departmentService.updateDepartment(
        authHeader,
        ticketDepartment
      );
      this.context.commit(DepartmentTypes.INSERT_DEPARTMENT, fm);
      this.context.commit(DepartmentTypes.SET_UPDATE_DEPARTMENT_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        DepartmentTypes.SET_UPDATE_DEPARTMENT_ERROR,
        "Failed to update department"
      );
    } finally {
      this.context.commit(DepartmentTypes.SET_UPDATE_DEPARTMENT_LOADING, false);
    }
  }
}

export default DepartmentModule;
