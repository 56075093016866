import { reversedash } from "@/filters/utils";
import {
  DownloadDetails,
  ReportFilter,
} from "@/store/modules/reports/reports.types";
import { UserTicket } from "@/store/modules/ticket/ticket.types";
import axios from "axios";

export async function getTicketReports(
  authKey: string,
  payload: ReportFilter
): Promise<UserTicket[]> {
  let url = "/v1/reports/tickets-report";
  if (payload.category) {
    url += `${url.includes("?") ? "&" : "?"}category=${payload.category}`;
  }

  if (payload.product) {
    url += `${url.includes("?") ? "&" : "?"}product=${payload.product}`;
  }

  if (payload.priority) {
    url += `${url.includes("?") ? "&" : "?"}priority=${payload.priority}`;
  }

  if (payload.assignee) {
    url += `${url.includes("?") ? "&" : "?"}assignee=${payload.assignee}`;
  }

  if (payload.status) {
    url += `${url.includes("?") ? "&" : "?"}status=${payload.status}`;
  }

  if (payload.startDate) {
    url += `${url.includes("?") ? "&" : "?"}startDate=${reversedash(
      payload.startDate
    )}`;
  }

  if (payload.endDate) {
    url += `${url.includes("?") ? "&" : "?"}endDate=${reversedash(
      payload.endDate
    )}`;
  }

  const response = await axios.get<UserTicket[]>(url, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function downloadCSVTicketReports(
  authKey: string,
  payload: ReportFilter
): Promise<DownloadDetails> {
  let url = "/v1/reports/download-report";
  if (payload.category) {
    url += `${url.includes("?") ? "&" : "?"}category=${payload.category}`;
  }

  if (payload.product) {
    url += `${url.includes("?") ? "&" : "?"}product=${payload.product}`;
  }

  if (payload.priority) {
    url += `${url.includes("?") ? "&" : "?"}priority=${payload.priority}`;
  }

  if (payload.assignee) {
    url += `${url.includes("?") ? "&" : "?"}assignee=${payload.assignee}`;
  }

  if (payload.status) {
    url += `${url.includes("?") ? "&" : "?"}status=${payload.status}`;
  }

  if (payload.startDate) {
    url += `${url.includes("?") ? "&" : "?"}startDate=${reversedash(
      payload.startDate
    )}`;
  }

  if (payload.endDate) {
    url += `${url.includes("?") ? "&" : "?"}endDate=${reversedash(
      payload.endDate
    )}`;
  }

  const response = await axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: authKey,
    },
  });

  let contentDisposition = response.headers["content-disposition"];
  if (!contentDisposition) {
    contentDisposition = response.headers["Content-Disposition"];
  }

  let fileName = "unknown.csv";
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.split("filename=");
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  }

  return { name: fileName, data: response.data };
}

export default {
  getTicketReports,
  downloadCSVTicketReports,
};
