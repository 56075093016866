import { DefaultState } from "@/types/types";

export type SystemUser = {
  id: number;
  firstname: string;
  lastname: string;
  name: string;
  username: string;
  email: string;
  phone: string;
  department: string;
  role: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type AddSystemUser = {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  phone: string;
  password: string;
  department?: string;
  role?: string;
  active: boolean;
};

export type UsersState = {
  users: SystemUser[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetUsersResult = {
  users: SystemUser[];
  pages: number;
};

export enum UserTypes {
  // States
  USERS = "USERS",
  USER_DATA = "USER_DATA",
  USER_PROFILE = "USER_PROFILE",
  ADD_USER_STATE = "ADD_USER_STATE",
  UPDATE_USER_STATE = "UPDATE_USER_STATE",
  DELETE_USER_STATE = "DELETE_USER_STATE",
  LOADING_USERS_STATE = "LOADING_USERS_STATE",
  LOADING_USER_PROFILE = "LOADING_USER_PROFILE",

  // Actions
  LOAD_USERS = "LOAD_USERS",
  LOAD_USER_PROFILE = "LOAD_USER_PROFILE",
  ADD_USER = "ADD_USER",
  DELETE_USER = "DELETE_USER",
  UPDATE_USER = "UPDATE_USER",

  // Mutations
  SET_LOADING_USER = "SET_LOADING_USER",
  SET_LOADING_USER_PROFILE = "SET_LOADING_USER_PROFILE",

  INSERT_USER = "INSERT_USER",
  INSERT_USERS = "INSERT_USERS",
  REMOVE_USER = "REMOVE_USER",

  SET_USER_PROFILE = "SET_USER_PROFILE",
  SET_TICKET_USER_DATA = "SET_TICKET_USER_DATA",
  SET_USER_PAGES = "SET_USER_PAGES",
  SET_ADD_USER_DIALOG_REF = "SET_ADD_USER_DIALOG_REF",

  SET_ADD_USER_LOADING = "SET_ADD_USER_LOADING",
  SET_ADD_USER_DIALOG = "SET_ADD_USER_DIALOG",
  SET_ADD_USER_ERROR = "SET_ADD_USER_ERROR",

  SET_UPDATE_USER_LOADING = "SET_UPDATE_USER_LOADING",
  SET_UPDATE_USER_DIALOG = "SET_UPDATE_USER_DIALOG",
  SET_UPDATE_USER_ERROR = "SET_UPDATE_USER_ERROR",

  SET_DELETE_USER_LOADING = "SET_DELETE_USER_LOADING",
  SET_DELETE_USER_DIALOG = "SET_DELETE_USER_DIALOG",
  SET_DELETE_USER_ERROR = "SET_DELETE_USER_ERROR",
}
