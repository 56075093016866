import { AddSiteZone, SiteZone } from "@/store/modules/zone/zone.types";
import axios from "axios";
import { AddZoneRequest } from "i-tracking-proto/js/v1/zone/rpc_add_zone_pb";
import { GetZonesRequest } from "i-tracking-proto/js/v1/zone/rpc_get_zones_pb";
import { PrioritySLA } from "i-tracking-proto/js/v1/zone/zone_pb";

export async function addZone(
  authKey: string,
  payload: AddSiteZone
): Promise<SiteZone> {
  const request = new AddZoneRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);
  request.setPrioritySlasList(
    payload.slas.map((x) => {
      const sla = new PrioritySLA();
      sla.setName(x.priority);
      sla.setSla(x.sla);

      return sla;
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const req: any = request.toObject();
  req.prioritySlas = req["prioritySlasList"];
  delete req["prioritySlasList"];

  const response = await axios.post<SiteZone>("/v1/zone", req, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function updateZone(
  authKey: string,
  payload: SiteZone
): Promise<SiteZone> {
  const response = await axios.patch<SiteZone>(`/v1/zone`, payload, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export async function deleteZone(
  authKey: string,
  payload: SiteZone
): Promise<void> {
  await axios.delete<void>(`/v1/zone/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getZones(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<SiteZone[]> {
  const addZoneRequest = new GetZonesRequest();
  addZoneRequest.setPage(page);
  addZoneRequest.setLimit(offset);
  addZoneRequest.setQuery(query);

  const response = await axios.get<SiteZone[]>("/v1/zones", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addZone,
  getZones,
  updateZone,
  deleteZone,
};
