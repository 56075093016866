import { DefaultState } from "@/types/types";
import { PrioritySLA, Sla } from "../zone/zone.types";

export type IssuePriority = {
  id: number;
  name: string;
  description: string;
  precedence: number;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
  zones: Sla[];
};

export type AddIssuePriority = {
  name: string;
  description: string;
  active: boolean;
  precedence: number;
  slas: PrioritySLA[];
};

export type PrioritiesState = {
  priorities: IssuePriority[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetTicketsResult = {
  priorities: IssuePriority[];
  pages: number;
};

export enum PriorityTypes {
  // States
  PRIORITIES = "PRIORITIES",
  PRIORITY_DATA = "PRIORITY_DATA",
  ADD_PRIORITY_STATE = "ADD_PRIORITY_STATE",
  UPDATE_PRIORITY_STATE = "UPDATE_PRIORITY_STATE",
  DELETE_PRIORITY_STATE = "DELETE_PRIORITY_STATE",
  LOADING_PRIORITIES_STATE = "LOADING_PRIORITIES_STATE",

  // Actions
  LOAD_PRIORITIES = "LOAD_PRIORITIES",
  ADD_PRIORITY = "ADD_PRIORITY",
  DELETE_PRIORITY = "DELETE_PRIORITY",
  UPDATE_PRIORITY = "UPDATE_PRIORITY",

  // Mutations
  SET_LOADING_PRIORITY = "SET_LOADING_PRIORITY",

  INSERT_PRIORITY = "INSERT_PRIORITY",
  INSERT_PRIORITYS = "INSERT_PRIORITYS",
  REMOVE_PRIORITY = "REMOVE_PRIORITY",

  SET_TICKET_PRIORITY_DATA = "SET_TICKET_PRIORITY_DATA",
  SET_PRIORITY_PAGES = "SET_PRIORITY_PAGES",
  SET_ADD_PRIORITY_DIALOG_REF = "SET_ADD_PRIORITY_DIALOG_REF",

  SET_ADD_PRIORITY_LOADING = "SET_ADD_PRIORITY_LOADING",
  SET_ADD_PRIORITY_DIALOG = "SET_ADD_PRIORITY_DIALOG",
  SET_ADD_PRIORITY_ERROR = "SET_ADD_PRIORITY_ERROR",

  SET_UPDATE_PRIORITY_LOADING = "SET_UPDATE_PRIORITY_LOADING",
  SET_UPDATE_PRIORITY_DIALOG = "SET_UPDATE_PRIORITY_DIALOG",
  SET_UPDATE_PRIORITY_ERROR = "SET_UPDATE_PRIORITY_ERROR",

  SET_DELETE_PRIORITY_LOADING = "SET_DELETE_PRIORITY_LOADING",
  SET_DELETE_PRIORITY_DIALOG = "SET_DELETE_PRIORITY_DIALOG",
  SET_DELETE_PRIORITY_ERROR = "SET_DELETE_PRIORITY_ERROR",
}
