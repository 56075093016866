import { UserTicket } from "../ticket/ticket.types";

export type ReportFilter = {
  category?: string;
  product?: string;
  priority?: string;
  assignee?: string;
  status?: boolean;
  startDate?: Date;
  endDate?: Date;
  force?: boolean;
};

export type ReportState = {
  loading: boolean;
  tickets: UserTicket[];
};

export type DownloadDetails = {
  data: Blob;
  name: string;
};

export enum ReportTypes {
  MODULE = "REPORTS",
  TICKET_REPORTS = "TICKET_REPORTS",
  LOAD_TICKET_REPORTS = "LOAD_TICKET_REPORTS",
  SET_TICKET_REPORTS = "SET_TICKET_REPORTS",
  SET_LOADING_TICKET_REPORTS = "SET_LOADING_TICKET_REPORTS",
  SET_LOADING_TICKET_REPORTS_ERROR = "SET_LOADING_TICKET_REPORTS_ERROR",

  DOWNLOAD_CSV_TICKET_REPORTS = "DOWNLOAD_CSV_TICKET_REPORTS",
  DOWNLOADING_CSV_TICKET_REPORTS = "DOWNLOADING_CSV_TICKET_REPORTS",
  SET_DOWNLOADING_CSV_TICKET_REPORTS = "SET_DOWNLOADING_CSV_TICKET_REPORTS",

  TICKET_REPORT_FILTER = "TICKET_REPORT_FILTER",
  SET_TICKET_REPORT_FILTER = "SET_TICKET_REPORT_FILTER",
}
