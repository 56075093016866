import { DefaultState } from "@/types/types";

export type TicketCategory = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type AddTicketCategory = {
  name: string;
  description: string;
  active: boolean;
};

export type CategoriesState = {
  categories: TicketCategory[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetTicketsResult = {
  categories: TicketCategory[];
  pages: number;
};

export enum CategoryTypes {
  // States
  CATEGORIES = "CATEGORIES",
  CATEGORY_DATA = "CATEGORY_DATA",
  ADD_CATEGORY_STATE = "ADD_CATEGORY_STATE",
  UPDATE_CATEGORY_STATE = "UPDATE_CATEGORY_STATE",
  DELETE_CATEGORY_STATE = "DELETE_CATEGORY_STATE",
  LOADING_CATEGORIES_STATE = "LOADING_CATEGORIES_STATE",

  // Actions
  LOAD_CATEGORYS = "LOAD_CATEGORYS",
  ADD_CATEGORY = "ADD_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",

  // Mutations
  SET_LOADING_CATEGORY = "SET_LOADING_CATEGORY",

  INSERT_CATEGORY = "INSERT_CATEGORY",
  INSERT_CATEGORYS = "INSERT_CATEGORYS",
  REMOVE_CATEGORY = "REMOVE_CATEGORY",

  SET_TICKET_CATEGORY_DATA = "SET_TICKET_CATEGORY_DATA",
  SET_CATEGORY_PAGES = "SET_CATEGORY_PAGES",
  SET_ADD_CATEGORY_DIALOG_REF = "SET_ADD_CATEGORY_DIALOG_REF",

  SET_ADD_CATEGORY_LOADING = "SET_ADD_CATEGORY_LOADING",
  SET_ADD_CATEGORY_DIALOG = "SET_ADD_CATEGORY_DIALOG",
  SET_ADD_CATEGORY_ERROR = "SET_ADD_CATEGORY_ERROR",

  SET_UPDATE_CATEGORY_LOADING = "SET_UPDATE_CATEGORY_LOADING",
  SET_UPDATE_CATEGORY_DIALOG = "SET_UPDATE_CATEGORY_DIALOG",
  SET_UPDATE_CATEGORY_ERROR = "SET_UPDATE_CATEGORY_ERROR",

  SET_DELETE_CATEGORY_LOADING = "SET_DELETE_CATEGORY_LOADING",
  SET_DELETE_CATEGORY_DIALOG = "SET_DELETE_CATEGORY_DIALOG",
  SET_DELETE_CATEGORY_ERROR = "SET_DELETE_CATEGORY_ERROR",
}
