const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Filters
export function pad(value: number): string {
  if (!value) return "";
  return String(value).padStart(5, "0");
}

export function uuid(value: number): string {
  if (!value) return "";
  const parts = String(value).split("-");
  if (parts.length > 0) {
    return parts[0].toLocaleUpperCase();
  }

  return "";
}

export function slashDate(value: number): string {
  if (!value) return "";
  const date = new Date(value * 1000);
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
}

export function slash(date: Date): string {
  if (!date) return "";
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
}

export function dash(date: unknown): string {
  try {
    if (!date) return "";
    if (date instanceof Date) {
      return `${String(date.getDate()).padStart(2, "0")}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${date.getFullYear()}`;
    }

    if (typeof date === "string") {
      const nddate = new Date(date.toString());
      return `${String(nddate.getDate()).padStart(2, "0")}-${String(
        nddate.getMonth() + 1
      ).padStart(2, "0")}-${nddate.getFullYear()}`;
    }

    return "";
  } catch (e) {
    return "";
  }
}

export function dashWithTime(date: unknown): string {
  try {
    if (!date) return "";

    let tDate: Date;
    if (date instanceof Date) {
      tDate = date;
    } else if (typeof date === "string") {
      tDate = new Date(date.toString());
    } else {
      throw "Invalid date";
    }

    return `${String(tDate.getDate()).padStart(2, "0")}-${String(
      tDate.getMonth() + 1
    ).padStart(2, "0")}-${tDate.getFullYear()} ${String(
      tDate.getHours()
    ).padStart(2, "0")}:${String(tDate.getMinutes()).padStart(2, "0")}`;
  } catch (e) {
    return "";
  }
}

export function reversedash(date: unknown): string {
  try {
    if (!date) return "";
    if (date instanceof Date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    }

    if (typeof date === "string") {
      const nddate = new Date(date.toString());
      return `${nddate.getFullYear()}-${String(nddate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(nddate.getDate()).padStart(2, "0")}`;
    }

    return "";
  } catch (e) {
    return "";
  }
}

export function commaDate(value: number): string {
  if (!value) return "";
  const date = new Date(value * 1000);
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
}

export function currency(value: number): string {
  if (!value) return "0";
  return value.toLocaleString();
}

export function uppercase(value: string): string {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
}
