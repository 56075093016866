import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import {
  Analytic,
  AnalyticsTypes,
  AnalyticFilter,
  AnalyticsState,
  DashboardAnalyticsState,
  DashboardAnalytics,
  DashboardFilter,
} from "./analytics.types";
import analyticsService from "@/services/analytics.service";
import { AxiosError } from "axios";
import { SignInTypes } from "../auth/auth.types";
import { FilterType } from "@/types/types";

@Module({ namespaced: true })
export default class Analytics extends VuexModule {
  public [AnalyticsTypes.DASHBOARD_FILTER_TYPES]: FilterType[] = [
    { title: "Daily", value: "Day" },
    { title: "Weekly", value: "Week" },
    { title: "Monthly", value: "Month" },
    { title: "Quaterly", value: "Quarter" },
    { title: "Annual", value: "Year" },
  ];

  public [AnalyticsTypes.DASHBOARD_FILTER]: DashboardFilter = {};

  @Mutation
  public [AnalyticsTypes.SET_DASHBOARD_FILTER](filter: DashboardFilter): void {
    this[AnalyticsTypes.DASHBOARD_FILTER] = filter;
  }

  public [AnalyticsTypes.ANALYTICS_FILTER]: AnalyticFilter = {};

  @Mutation
  public [AnalyticsTypes.SET_ANALYTICS_FILTER](filter: AnalyticFilter): void {
    this[AnalyticsTypes.ANALYTICS_FILTER] = filter;
  }

  // Product Analytics
  public [AnalyticsTypes.PRODUCT_ANALYTICS]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_PRODUCT_ANALYTICS](analytics: Analytic[]): void {
    this[AnalyticsTypes.PRODUCT_ANALYTICS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_PRODUCT_ANALYTICS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.PRODUCT_ANALYTICS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_PRODUCT_ANALYTICS](
    filter: AnalyticFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.PRODUCT_ANALYTICS].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      // merge two filters with priority to passed one
      filter = {
        start: filter.start || this[AnalyticsTypes.ANALYTICS_FILTER].start,
        end: filter.end || this[AnalyticsTypes.ANALYTICS_FILTER].end,
      };
    }

    this.context.commit(AnalyticsTypes.SET_ANALYTICS_FILTER, filter);

    this.context.commit(AnalyticsTypes.SET_LOADING_PRODUCT_ANALYTICS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getProductAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_PRODUCT_ANALYTICS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load product analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(AnalyticsTypes.SET_LOADING_PRODUCT_ANALYTICS, false);
    }
  }
  // Dashboard Analytics
  public [AnalyticsTypes.DASHBOARD_ANALYTICS]: DashboardAnalyticsState = {
    analytics: {
      statistics: [],
      total: 0,
      percentClosed: 0,
    },
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_DASHBOARD_ANALYTICS](
    analytics: DashboardAnalytics
  ): void {
    this[AnalyticsTypes.DASHBOARD_ANALYTICS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_DASHBOARD_ANALYTICS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.DASHBOARD_ANALYTICS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_DASHBOARD_ANALYTICS](
    filter: DashboardFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.DASHBOARD_ANALYTICS].analytics.statistics.length >
        0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.DASHBOARD_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_DASHBOARD_ANALYTICS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getDashboardStatistics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_ANALYTICS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load dashboard analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(
        AnalyticsTypes.SET_LOADING_DASHBOARD_ANALYTICS,
        false
      );
    }
  }

  // Category Analytics
  public [AnalyticsTypes.CATEGORY_ANALYTICS]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_CATEGORY_ANALYTICS](analytics: Analytic[]): void {
    this[AnalyticsTypes.CATEGORY_ANALYTICS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_CATEGORY_ANALYTICS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.CATEGORY_ANALYTICS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_CATEGORY_ANALYTICS](
    filter: AnalyticFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.CATEGORY_ANALYTICS].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.ANALYTICS_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_ANALYTICS_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_CATEGORY_ANALYTICS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getCategoryAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_CATEGORY_ANALYTICS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load category analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(AnalyticsTypes.SET_LOADING_CATEGORY_ANALYTICS, false);
    }
  }
  // Assignee Analytics
  public [AnalyticsTypes.ASSIGNEE_ANALYTICS]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_ASSIGNEE_ANALYTICS](analytics: Analytic[]): void {
    this[AnalyticsTypes.ASSIGNEE_ANALYTICS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_ASSIGNEE_ANALYTICS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.ASSIGNEE_ANALYTICS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_ASSIGNEE_ANALYTICS](
    filter: AnalyticFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.ASSIGNEE_ANALYTICS].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.ANALYTICS_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_ANALYTICS_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_ASSIGNEE_ANALYTICS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getAssigneeAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_ASSIGNEE_ANALYTICS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load assignee analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(AnalyticsTypes.SET_LOADING_ASSIGNEE_ANALYTICS, false);
    }
  }

  // Priority Analytics
  public [AnalyticsTypes.PRIORITY_ANALYTICS]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_PRIORITY_ANALYTICS](analytics: Analytic[]): void {
    this[AnalyticsTypes.PRIORITY_ANALYTICS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_PRIORITY_ANALYTICS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.PRIORITY_ANALYTICS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_PRIORITY_ANALYTICS](
    filter: AnalyticFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.PRIORITY_ANALYTICS].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.ANALYTICS_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_ANALYTICS_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_PRIORITY_ANALYTICS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getPriorityAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_PRIORITY_ANALYTICS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load priority analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(AnalyticsTypes.SET_LOADING_PRIORITY_ANALYTICS, false);
    }
  }

  // Dashboard Analytics
  public [AnalyticsTypes.DASHBOARD_STATUS]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_DASHBOARD_STATUS](analytics: Analytic[]): void {
    this[AnalyticsTypes.DASHBOARD_STATUS].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_DASHBOARD_STATUS](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.DASHBOARD_STATUS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_DASHBOARD_STATUS](
    filter: DashboardFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.DASHBOARD_STATUS].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.DASHBOARD_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_DASHBOARD_STATUS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getDashboardAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_STATUS, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load dashbaord analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(AnalyticsTypes.SET_LOADING_DASHBOARD_STATUS, false);
    }
  }

  // Dashboard Analytics
  public [AnalyticsTypes.DASHBOARD_CATEGORIES]: AnalyticsState = {
    analytics: [],
    loading: false,
  };

  @Mutation
  public [AnalyticsTypes.SET_DASHBOARD_CATEGORIES](
    analytics: Analytic[]
  ): void {
    this[AnalyticsTypes.DASHBOARD_CATEGORIES].analytics = analytics;
  }

  @Mutation
  public [AnalyticsTypes.SET_LOADING_DASHBOARD_CATEGORIES](
    isLoading: boolean
  ): void {
    this[AnalyticsTypes.DASHBOARD_CATEGORIES].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [AnalyticsTypes.LOAD_DASHBOARD_CATEGORIES](
    filter: DashboardFilter = {}
  ): Promise<void> {
    if (
      this[AnalyticsTypes.DASHBOARD_CATEGORIES].analytics.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      filter = this[AnalyticsTypes.DASHBOARD_FILTER];
    } else {
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_FILTER, filter);
    }

    this.context.commit(AnalyticsTypes.SET_LOADING_DASHBOARD_CATEGORIES, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const analytics = await analyticsService.getDashboardCategoryAnalytics(
        authHeader,
        filter
      );
      this.context.commit(AnalyticsTypes.SET_DASHBOARD_CATEGORIES, analytics);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load dashbaord analytics",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(
        AnalyticsTypes.SET_LOADING_DASHBOARD_CATEGORIES,
        false
      );
    }
  }

  get [AnalyticsTypes.CURRENT_DASHBOARD_FILTER](): FilterType {
    if (this[AnalyticsTypes.DASHBOARD_FILTER].filterBy) {
      const currFilter = this[AnalyticsTypes.DASHBOARD_FILTER_TYPES].find(
        (x) => x.value === this[AnalyticsTypes.DASHBOARD_FILTER].filterBy
      );
      if (currFilter) {
        return currFilter;
      }
    }

    return this[AnalyticsTypes.DASHBOARD_FILTER_TYPES][1];
  }
}
