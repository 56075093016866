import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { setUpMocks } from "./services/mocks/index.mock";
import "./assets/styles/loader.css";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from "axios";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// if (process.env.VUE_APP_MOCK_API === "true") {
//   setUpMocks();
// }

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
