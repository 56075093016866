import { DefaultState } from "@/types/types";

export type SystemRole = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
  permissions: Permissions;
};

export type Permissions = {
  admin: boolean;
  viewTickets: boolean;
  viewAnalytics: boolean;
  viewAdminControl: boolean;
  viewReports: boolean;
  viewUsers: boolean;
  viewDepartments: boolean;
  viewRoles: boolean;

  modifyTickets: boolean;
  modifyUsers: boolean;
  modifyRoles: boolean;
  modifyDepartments: boolean;
  modifyReports: boolean;
};

export type AddSystemRole = {
  name: string;
  description: string;
  active: boolean;
  permissions: Permissions;
};

export type RolesState = {
  roles: SystemRole[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetRolesResult = {
  roles: SystemRole[];
  pages: number;
};

export enum RoleTypes {
  // States
  ROLES = "ROLES",
  ROLE_DATA = "ROLE_DATA",
  ADD_ROLE_STATE = "ADD_ROLE_STATE",
  UPDATE_ROLE_STATE = "UPDATE_ROLE_STATE",
  DELETE_ROLE_STATE = "DELETE_ROLE_STATE",
  LOADING_ROLES_STATE = "LOADING_ROLES_STATE",

  // Actions
  LOAD_ROLES = "LOAD_ROLES",
  ADD_ROLE = "ADD_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",

  // Mutations
  SET_LOADING_ROLE = "SET_LOADING_ROLE",

  INSERT_ROLE = "INSERT_ROLE",
  INSERT_ROLES = "INSERT_ROLES",
  REMOVE_ROLE = "REMOVE_ROLE",

  SET_TICKET_ROLE_DATA = "SET_TICKET_ROLE_DATA",
  SET_ROLE_PAGES = "SET_ROLE_PAGES",
  SET_ADD_ROLE_DIALOG_REF = "SET_ADD_ROLE_DIALOG_REF",

  SET_ADD_ROLE_LOADING = "SET_ADD_ROLE_LOADING",
  SET_ADD_ROLE_DIALOG = "SET_ADD_ROLE_DIALOG",
  SET_ADD_ROLE_ERROR = "SET_ADD_ROLE_ERROR",

  SET_UPDATE_ROLE_LOADING = "SET_UPDATE_ROLE_LOADING",
  SET_UPDATE_ROLE_DIALOG = "SET_UPDATE_ROLE_DIALOG",
  SET_UPDATE_ROLE_ERROR = "SET_UPDATE_ROLE_ERROR",

  SET_DELETE_ROLE_LOADING = "SET_DELETE_ROLE_LOADING",
  SET_DELETE_ROLE_DIALOG = "SET_DELETE_ROLE_DIALOG",
  SET_DELETE_ROLE_ERROR = "SET_DELETE_ROLE_ERROR",
}
