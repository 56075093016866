import ticketService from "@/services/ticket.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AnalyticFilter,
  AnalyticsTypes,
  DashboardFilter,
} from "../analytics/analytics.types";
import { SignInTypes } from "../auth/auth.types";
import { ReportFilter, ReportTypes } from "../reports/reports.types";
import { RootTypes } from "../root.types";
import {
  AddUserTicket,
  CloseTicketDetails,
  TicketTypes,
  UpdateTicketStatus,
  UserTicket,
} from "./ticket.types";

@Module({ namespaced: true })
class TicketModule extends VuexModule {
  public [TicketTypes.TICKETS]: UserTicket[] = [];
  public [TicketTypes.TOP_TICKETS]: UserTicket[] = [];
  public [TicketTypes.LOADING_TOP_TICKETS] = false;

  public [TicketTypes.TICKET_STATUSES] = [
    "New",
    "InProgress",
    "Resolved",
    "Closed",
    "Escalated",
  ];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public currentPage = 1;
  public pages = 0;
  public limit = 10;

  private cachedPages: {
    [key: number]: UserTicket[];
  } = {};

  public addTicketRef = 0;

  public [TicketTypes.TICKET_DATA]: UserTicket = {
    id: -1,
    category: "",
    issue: "",
    ticketNumber: "",
    caseNumber: "",
    description: "",
    reportingDate: new Date(),
    priority: "",
    status: "",
    product: "",
    site: "",
    sla: 0,
    createdAt: new Date(),
    loading: false,
  };

  public [TicketTypes.ADD_TICKET_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [TicketTypes.VIEW_TICKET_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [TicketTypes.DELETE_TICKET_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [TicketTypes.UPDATE_TICKET_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [TicketTypes.CLOSE_TICKET_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [TicketTypes.LOADING_TICKETS_STATE] = false;

  @Mutation
  public [TicketTypes.SET_ADD_TICKET_DIALOG_REF](ref: number): void {
    this.addTicketRef = ref;
  }

  @Mutation
  public [TicketTypes.SET_CURRENT_PAGE](page: number): void {
    this.currentPage = page;
  }

  @Mutation
  public [TicketTypes.SET_TICKET_TICKET_DATA](data: UserTicket): void {
    this[TicketTypes.TICKET_DATA] = data;
  }

  @Mutation
  public [TicketTypes.INSERT_TOP_TICKETS](data: UserTicket[]): void {
    this[TicketTypes.TOP_TICKETS] = data;
  }

  // Set ticket pages count
  @Mutation
  public [TicketTypes.SET_TICKET_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [TicketTypes.SET_LOADING_TICKET](isLoadingCard: boolean): void {
    this[TicketTypes.LOADING_TICKETS_STATE] = isLoadingCard;
  }

  @Mutation
  public [TicketTypes.SET_LOADING_TOP_TICKETS](
    isLoadingTopTickets: boolean
  ): void {
    this[TicketTypes.LOADING_TOP_TICKETS] = isLoadingTopTickets;
  }

  // Add Card
  @Mutation
  public [TicketTypes.SET_VIEW_TICKET_DIALOG](isViewingDialog: boolean): void {
    this[TicketTypes.VIEW_TICKET_STATE].dialog = isViewingDialog;
  }

  @Mutation
  public [TicketTypes.SET_ADD_TICKET_DIALOG](
    isAddingCardDialog: boolean
  ): void {
    this[TicketTypes.ADD_TICKET_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [TicketTypes.INSERT_TICKETS](tickets: UserTicket[]): void {
    this[TicketTypes.TICKETS].splice(0, this[TicketTypes.TICKETS].length);
    this[TicketTypes.TICKETS].push(...tickets);
  }

  @Mutation
  public [TicketTypes.SET_ADD_TICKET_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this[TicketTypes.ADD_TICKET_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [TicketTypes.SET_ADD_TICKET_ERROR](addError: string): void {
    this[TicketTypes.ADD_TICKET_STATE].error.errorMessage = addError;
    this[TicketTypes.ADD_TICKET_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [TicketTypes.SET_DELETE_TICKET_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[TicketTypes.DELETE_TICKET_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [TicketTypes.SET_DELETE_TICKET_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[TicketTypes.DELETE_TICKET_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [TicketTypes.SET_DELETE_TICKET_ERROR](deleteError: string): void {
    this[TicketTypes.DELETE_TICKET_STATE].error.errorMessage = deleteError;
    this[TicketTypes.DELETE_TICKET_STATE].error.error = true;
  }
  // Delete Card
  @Mutation
  public [TicketTypes.SET_CLOSE_TICKET_DIALOG](
    isClosingCardDialog: boolean
  ): void {
    this[TicketTypes.CLOSE_TICKET_STATE].dialog = isClosingCardDialog;
  }

  @Mutation
  public [TicketTypes.SET_CLOSE_TICKET_LOADING](
    isClosingCardLoading: boolean
  ): void {
    this[TicketTypes.CLOSE_TICKET_STATE].loading = isClosingCardLoading;
  }

  @Mutation
  public [TicketTypes.SET_CLOSE_TICKET_ERROR](deleteError: string): void {
    this[TicketTypes.CLOSE_TICKET_STATE].error.errorMessage = deleteError;
    this[TicketTypes.CLOSE_TICKET_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [TicketTypes.SET_UPDATE_TICKET_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[TicketTypes.UPDATE_TICKET_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [TicketTypes.SET_UPDATE_TICKET_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[TicketTypes.UPDATE_TICKET_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [TicketTypes.SET_UPDATE_TICKET_ERROR](updateError: string): void {
    this[TicketTypes.UPDATE_TICKET_STATE].error.errorMessage = updateError;
    this[TicketTypes.UPDATE_TICKET_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [TicketTypes.INSERT_TICKET](ticket: UserTicket): void {
    let page = -1;
    let index = -1;
    // const data = Object.assign({}, this.cachedPages);

    const pages = Object.keys(this.cachedPages);
    for (let key = 1; key <= Object.keys(this.cachedPages).length; key++) {
      const i = this.cachedPages[key].findIndex((x) => x.id === ticket.id);

      if (i > -1) {
        index = i;
        page = key as never as number;
        break;
      }
    }

    if (index > -1) {
      this.cachedPages[page].splice(index, 1, ticket);
    } else {
      page = 1;
      // If there are no pages, create one and insert in it
      if (pages.length === 0) {
        this.cachedPages[page] = [ticket];
      } else {
        for (let key = 1; key <= Object.keys(this.cachedPages).length; key++) {
          this.cachedPages[key].unshift(ticket);

          const newPageLength = this.cachedPages[key].length;
          if (newPageLength > this.limit) {
            ticket = this.cachedPages[key][newPageLength - 1];
            this.cachedPages[key].pop();

            // // Exceeded last page
            // if (!data[key + 1]) {
            //   data[key + 1] = [ticket];
            //   break;
            // }
          } else {
            break;
          }
        }
      }
    }

    this[TicketTypes.TICKETS] = this.cachedPages[this.currentPage];
  }

  // Remove Card
  @Mutation
  public [TicketTypes.SET_PAGE_DATA]({
    page,
    tickets,
  }: {
    page: number;
    tickets: UserTicket[];
  }): void {
    this.cachedPages[page] = tickets;
  }

  @Mutation
  public [TicketTypes.REMOVE_TICKET](ticket: UserTicket): void {
    const index = this[TicketTypes.TICKETS].map((x) => x.id).indexOf(ticket.id);

    if (index > -1) {
      this[TicketTypes.TICKETS].splice(index, 1);
    }
  }

  @Mutation
  public [TicketTypes.SET_TICKET_LOADING](payload: UpdateTicketLoading): void {
    const index = this[TicketTypes.TICKETS]
      .map((x) => x.id)
      .indexOf(payload.ticket.id);

    if (index > -1) {
      const ticket = this[TicketTypes.TICKETS][index];
      ticket.loading = payload.loading;
      this[TicketTypes.TICKETS].splice(index, 1, ticket);
    }
  }

  // Load Top Cards
  @Action
  public async [TicketTypes.LOAD_TOP_TICKETS](): Promise<void> {
    if (this[TicketTypes.TOP_TICKETS].length > 0) return;

    this.context.commit(TicketTypes.SET_LOADING_TOP_TICKETS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await ticketService.getTopTickets(authHeader);
      this.context.commit(TicketTypes.INSERT_TOP_TICKETS, response);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading top tickets" },
        { root: true }
      );
    } finally {
      this.context.commit(TicketTypes.SET_LOADING_TOP_TICKETS, false);
    }
  }
  // Load Top Cards
  @Action
  public async [TicketTypes.UPDATE_TICKET_STATUS](
    payload: UpdateTicketStatus
  ): Promise<void> {
    this.context.commit(TicketTypes.SET_TICKET_LOADING, <UpdateTicketLoading>{
      ticket: payload.ticket,
      loading: true,
    });

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await ticketService.changeTicketStatus(
        authHeader,
        payload
      );
      this.context.commit(TicketTypes.INSERT_TICKET, response);
      this.context.dispatch(TicketTypes.RELOAD_ANALYTICS);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error updating ticket status" },
        { root: true }
      );
    } finally {
      this.context.commit(TicketTypes.SET_TICKET_LOADING, <UpdateTicketLoading>{
        ticket: payload.ticket,
        loading: false,
      });
    }
  }
  // Load Cards
  @Action
  public async [TicketTypes.LOAD_TICKETS]({
    page,
    limit,
  }: // query,
  Pagination): Promise<void> {
    this.context.commit(TicketTypes.SET_CURRENT_PAGE, page);

    this.context.commit(TicketTypes.SET_LOADING_TICKET, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await ticketService.getTickets(
        authHeader,
        page,
        limit
        // query
      );
      this.context.commit(TicketTypes.INSERT_TICKETS, response.data);
      this.context.commit(TicketTypes.SET_TICKET_PAGES, response.count);
      this.context.commit(TicketTypes.SET_PAGE_DATA, {
        page,
        tickets: response.data,
      });
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading tickets" },
        { root: true }
      );
    } finally {
      this.context.commit(TicketTypes.SET_LOADING_TICKET, false);
    }
  }
  // Add Card
  @Action
  public async [TicketTypes.ADD_TICKET](ticket: AddUserTicket): Promise<void> {
    this.context.commit(TicketTypes.SET_ADD_TICKET_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await ticketService.addTicket(authHeader, ticket);
      // this.context.commit(TicketTypes.INSERT_TICKET, fm);
      this.context.commit(
        TicketTypes.SET_ADD_TICKET_DIALOG_REF,
        this.addTicketRef + 1
      );
      this.context.commit(TicketTypes.SET_ADD_TICKET_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Ticket Saved", color: "success" },
        { root: true }
      );
      this.context.dispatch(TicketTypes.RELOAD_ANALYTICS);
      this.context.dispatch(TicketTypes.LOAD_TICKETS, {
        page: this.currentPage,
        limit: this.limit,
      });
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add ticket" },
        { root: true }
      );
    } finally {
      this.context.commit(TicketTypes.SET_ADD_TICKET_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [TicketTypes.DELETE_TICKET](): Promise<void> {
    this.context.commit(TicketTypes.SET_DELETE_TICKET_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await ticketService.deleteTicket(
        authHeader,
        this[TicketTypes.TICKET_DATA]
      );
      // this.context.commit(
      //   TicketTypes.REMOVE_TICKET,
      //   this[TicketTypes.TICKET_DATA]
      // );
      this.context.commit(TicketTypes.SET_DELETE_TICKET_DIALOG, false);
      this.context.dispatch(TicketTypes.RELOAD_ANALYTICS);
      this.context.dispatch(TicketTypes.LOAD_TICKETS, {
        page: this.currentPage,
        limit: this.limit,
      });
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        TicketTypes.SET_DELETE_TICKET_ERROR,
        "Failed to delete ticket"
      );
    } finally {
      this.context.commit(TicketTypes.SET_DELETE_TICKET_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [TicketTypes.UPDATE_TICKET](
    ticketTicket: UserTicket
  ): Promise<void> {
    this.context.commit(TicketTypes.SET_UPDATE_TICKET_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await ticketService.updateTicket(authHeader, ticketTicket);
      this.context.commit(TicketTypes.INSERT_TICKET, fm);
      this.context.commit(TicketTypes.SET_UPDATE_TICKET_DIALOG, false);
      this.context.dispatch(TicketTypes.RELOAD_ANALYTICS);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        TicketTypes.SET_UPDATE_TICKET_ERROR,
        "Failed to update ticket"
      );
    } finally {
      this.context.commit(TicketTypes.SET_UPDATE_TICKET_LOADING, false);
    }
  }
  // Update Card
  @Action
  public async [TicketTypes.CLOSE_TICKET](
    details: CloseTicketDetails
  ): Promise<void> {
    this.context.commit(TicketTypes.SET_CLOSE_TICKET_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await ticketService.closeTicket(authHeader, {
        ticket: this[TicketTypes.TICKET_DATA],
        details,
      });
      this.context.commit(TicketTypes.INSERT_TICKET, fm);
      this.context.commit(TicketTypes.SET_CLOSE_TICKET_DIALOG, false);

      this.context.dispatch(TicketTypes.RELOAD_ANALYTICS);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        TicketTypes.SET_CLOSE_TICKET_ERROR,
        "Failed to close ticket"
      );
    } finally {
      this.context.commit(TicketTypes.SET_CLOSE_TICKET_LOADING, false);
    }
  }

  @Action
  public [TicketTypes.RELOAD_ANALYTICS](): void {
    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_ASSIGNEE_ANALYTICS}`,
      <AnalyticFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_CATEGORY_ANALYTICS}`,
      <AnalyticFilter>{
        force: true,
      },
      { root: true }
    );
    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_PRIORITY_ANALYTICS}`,
      <AnalyticFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_PRODUCT_ANALYTICS}`,
      <AnalyticFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_DASHBOARD_ANALYTICS}`,
      <DashboardFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_DASHBOARD_CATEGORIES}`,
      <DashboardFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${AnalyticsTypes.MODULE}/${AnalyticsTypes.LOAD_DASHBOARD_STATUS}`,
      <DashboardFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(
      `${ReportTypes.MODULE}/${ReportTypes.LOAD_TICKET_REPORTS}`,
      <ReportFilter>{
        force: true,
      },
      { root: true }
    );

    this.context.dispatch(TicketTypes.LOAD_TOP_TICKETS);
  }
}

type UpdateTicketLoading = {
  ticket: UserTicket;
  loading: boolean;
};

export default TicketModule;
