import { DefaultState } from "@/types/types";

export type UserDepartment = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type AddUserDepartment = {
  name: string;
  description: string;
  active: boolean;
};

export type DepartmentsState = {
  departments: UserDepartment[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetDepartmentsResult = {
  departments: UserDepartment[];
  pages: number;
};

export enum DepartmentTypes {
  // States
  DEPARTMENTS = "DEPARTMENTS",
  DEPARTMENT_DATA = "DEPARTMENT_DATA",
  ADD_DEPARTMENT_STATE = "ADD_DEPARTMENT_STATE",
  UPDATE_DEPARTMENT_STATE = "UPDATE_DEPARTMENT_STATE",
  DELETE_DEPARTMENT_STATE = "DELETE_DEPARTMENT_STATE",
  LOADING_DEPARTMENTS_STATE = "LOADING_DEPARTMENTS_STATE",

  // Actions
  LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS",
  ADD_DEPARTMENT = "ADD_DEPARTMENT",
  DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",

  // Mutations
  SET_LOADING_DEPARTMENT = "SET_LOADING_DEPARTMENT",

  INSERT_DEPARTMENT = "INSERT_DEPARTMENT",
  INSERT_DEPARTMENTS = "INSERT_DEPARTMENTS",
  REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT",

  SET_TICKET_DEPARTMENT_DATA = "SET_TICKET_DEPARTMENT_DATA",
  SET_DEPARTMENT_PAGES = "SET_DEPARTMENT_PAGES",
  SET_ADD_DEPARTMENT_DIALOG_REF = "SET_ADD_DEPARTMENT_DIALOG_REF",

  SET_ADD_DEPARTMENT_LOADING = "SET_ADD_DEPARTMENT_LOADING",
  SET_ADD_DEPARTMENT_DIALOG = "SET_ADD_DEPARTMENT_DIALOG",
  SET_ADD_DEPARTMENT_ERROR = "SET_ADD_DEPARTMENT_ERROR",

  SET_UPDATE_DEPARTMENT_LOADING = "SET_UPDATE_DEPARTMENT_LOADING",
  SET_UPDATE_DEPARTMENT_DIALOG = "SET_UPDATE_DEPARTMENT_DIALOG",
  SET_UPDATE_DEPARTMENT_ERROR = "SET_UPDATE_DEPARTMENT_ERROR",

  SET_DELETE_DEPARTMENT_LOADING = "SET_DELETE_DEPARTMENT_LOADING",
  SET_DELETE_DEPARTMENT_DIALOG = "SET_DELETE_DEPARTMENT_DIALOG",
  SET_DELETE_DEPARTMENT_ERROR = "SET_DELETE_DEPARTMENT_ERROR",
}
