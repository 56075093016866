import { Component, Vue } from "vue-property-decorator";

@Component
export class ValidationMixin extends Vue {
  public validationRules = {
    required: (value: unknown): boolean | string => !!value || "Required.",
    arrayRequired: (value: unknown[]): boolean | string =>
      value.length > 0 || "Required.",
    number: (value: number): boolean | string =>
      !isNaN(value) || "Invalid Number.",
    greaterThanZero: (v: number): boolean | string =>
      v > 0 || "Must be greater than zero",
    min: (v: string): boolean | string => v.length >= 8 || "Min 8 characters",
    password: [
      (v: string): boolean | string => !!v || "Password required",
      (v: string): boolean | string =>
        /[a-z]/.test(v) || "Password must contain at least one small letter.",
      (v: string): boolean | string =>
        /[A-Z]/.test(v) || "Password must contain at least one capital letter.",
      (v: string): boolean | string =>
        /[0-9]/.test(v) || "Password must contain at least one number.",
      (v: string): boolean | string =>
        /[$&+,:;=?@#|'<>.^*()%!-]/.test(v) ||
        "Password must contain at least one special character.",

      (v: string): boolean | string =>
        v.length > 8 || "Password must not be less than 8 characters",
    ],
    username: [
      (v: string): boolean | string => !!v || "Username required",
      (v: string): boolean | string =>
        !/[ ]/.test(v) || "Username can not have spaces.",
    ],
    email: [
      (v: string): boolean | string => !!v || "Email required",
      (v: string): boolean | string =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Invalid Email Address.",
    ],
    firstName: [
      (v: string): boolean | string => !!v || "First Name is required",
      (v: string): boolean | string =>
        !/[ ]/.test(v) || "First Name can not have spaces.",
    ],
    lastName: [(v: string): boolean | string => !!v || "Last Name is required"],
    gender: [(v: string): boolean | string => !!v || "Gender is required"],
    dob: [(v: string): boolean | string => !!v || "Date of Birth is required"],
    name: [(v: string): boolean | string => !!v || "Name required"],
    phone: [
      (v: string): boolean | string => !!v || "Required",
      (v: string): boolean | string =>
        /^\+?[0-9]{10,14}$/.test(v.trim()) ||
        "Invalid phone. e.g 077XXXXXXX or +2567XXXXXXXX",
    ],
    address: [
      (v: string): boolean | string => !!v || "Address required",
      (v: string): boolean | string =>
        v.length <= 41 || "Address name too long",
      (v: string): boolean | string =>
        /^[\u0080-\uFFFFa-zA-Z0-9\-.,'#*@\\/& ]+$/.test(v.trim()) ||
        "Address name can not contain special characters.",
    ],
  };
}

export default ValidationMixin;
