import store from "@/store";
import { RootTypes } from "@/store/modules/root.types";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/auth/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "rese-password" */ "../views/auth/ResetPassword.vue"
      ),
  },
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/main/HomeView.vue"),
    redirect: "/home/dashboard",
    children: [
      {
        path: "/home/dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/main/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "../views/main/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tickets",
        name: "tickets",
        component: () =>
          import(/* webpackChunkName: "tickets" */ "../views/main/Tickets.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/analytics/user",
        name: "user-analytics",
        component: () =>
          import(
            /* webpackChunkName: "user-analytics" */ "../views/main/analytics/UserAnalytics.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/analytics/activities",
        name: "activity-analytics",
        component: () =>
          import(
            /* webpackChunkName: "activity-analytics" */ "../views/main/analytics/ActivityAnalytics.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/reports/general",
        name: "general-reports",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "../views/main/reports/GeneralReports.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/user-control",
        name: "admin-user-control",
        component: () =>
          import(
            /* webpackChunkName: "admin-user-control" */ "../views/main/admin/UserControl.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/departments",
        name: "admin-departments",
        component: () =>
          import(
            /* webpackChunkName: "admin-departments" */ "../views/main/admin/Departments.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/site-stations",
        name: "admin-site-stations",
        component: () =>
          import(
            /* webpackChunkName: "admin-site-stations" */ "../views/main/admin/SiteStations.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/products",
        name: "admin-products",
        component: () =>
          import(
            /* webpackChunkName: "admin-products" */ "../views/main/admin/Products.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/system-roles",
        name: "admin-system-roles",
        component: () =>
          import(
            /* webpackChunkName: "admin-system-roles" */ "../views/main/admin/SystemRoles.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/site-zones",
        name: "admin-site-zones",
        component: () =>
          import(
            /* webpackChunkName: "admin-site-zones" */ "../views/main/admin/SiteZones.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/issue-classifications",
        name: "admin-issue-classifications",
        component: () =>
          import(
            /* webpackChunkName: "admin-issue-classifications" */ "../views/main/admin/IssuePriorities.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/admin/ticket-categories",
        name: "admin-ticket-categories",
        component: () =>
          import(
            /* webpackChunkName: "admin-ticket-categories" */ "../views/main/admin/TicketCategories.vue"
          ),
      },
      {
        path: "/home/settings",
        name: "settings",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/main/Settings.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.afterEach(() => {
  // Remove initial loading
  store.commit(RootTypes.setLoading, false);
});

router.beforeEach((to, from, next) => {
  // Start initial loading
  store.commit(RootTypes.setLoading, true);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Auth/isLoggedIn"]) {
      next();
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["Auth/isLoggedIn"]) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
