import router from "@/router";
import authService from "@/services/auth.service";
import userService from "@/services/user.service";
import { DefaultState } from "@/types/types";
import CryptoJS from "crypto-js";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import {
  JsUserProfile,
  ResetPasswordPayload,
  SignByUsernamePayload,
  SignInState,
  SignInTypes,
  TokenPayload,
  UpdatePasswordPayload,
  UserDetails,
} from "./auth.types";

@Module({ namespaced: true })
class Auth extends VuexModule {
  public authenticated = false;
  public secret: string | null = null;

  private secretKey = process.env.VUE_APP_SECRET_KEY;
  private accessTokenKey = process.env.VUE_APP_ACCESS_TOKEN_KEY;
  private userKey = process.env.VUE_APP_USER_KEY;
  private refreshTokenKey = process.env.VUE_APP_REFRESH_TOKEN_KEY;

  private lastPath = "/";

  public accessToken = this.parseToken(this.accessTokenKey);
  public refreshToken = this.parseToken(this.refreshTokenKey);
  public userProfile = this.parseUserProfile();

  public signIn: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  public signOut: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  public refreshTokenState: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  @Mutation
  public [SignInTypes.SET_AUTHENTICATED](isAuthenticated: boolean): void {
    this.authenticated = isAuthenticated;
  }

  @Mutation
  public [SignInTypes.SET_LAST_PATH](path: string): void {
    this.lastPath = path;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_SECRET](secret: string): void {
    this.secret = secret;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_LOADING](isLoading: boolean): void {
    this.signIn.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_ERROR](errorMessage: string): void {
    this.signIn.error = true;
    this.signIn.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_SIGN_IN_ERROR](): void {
    this.signIn.error = false;
    this.signIn.errorMessage = null;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_OUT_LOADING](isLoading: boolean): void {
    this.signOut.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_OUT_ERROR](errorMessage: string): void {
    this.signOut.error = true;
    this.signOut.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_SIGN_OUT_ERROR](): void {
    this.signOut.error = false;
    this.signOut.errorMessage = null;
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN_LOADING](isLoading: boolean): void {
    this.refreshTokenState.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN_ERROR](errorMessage: string): void {
    this.refreshTokenState.error = true;
    this.refreshTokenState.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_REFRESH_TOKEN_ERROR](): void {
    this.refreshTokenState.error = false;
    this.refreshTokenState.errorMessage = null;
  }

  // Tokens
  @Mutation
  public [SignInTypes.SET_ACCESS_TOKEN](token?: TokenPayload): void {
    if (token) {
      const accessToken = CryptoJS.AES.encrypt(
        JSON.stringify(token),
        this.secretKey
      ).toString();

      localStorage.setItem(this.accessTokenKey, accessToken);

      this.accessToken = token;
    } else {
      localStorage.removeItem(this.accessTokenKey);
      this.accessToken = null;
    }
  }

  @Mutation
  public [SignInTypes.CLEAR_ACCESS_TOKEN](): void {
    localStorage.removeItem(this.accessTokenKey);
    this.accessToken = null;
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN](token?: TokenPayload): void {
    if (token) {
      const refreshToken = CryptoJS.AES.encrypt(
        JSON.stringify(token),
        this.secretKey
      ).toString();

      localStorage.setItem(this.refreshTokenKey, refreshToken);

      this.refreshToken = token;
    } else {
      localStorage.removeItem(this.refreshTokenKey);
      this.refreshToken = null;
    }
  }

  @Mutation
  public [SignInTypes.CLEAR_REFRESH_TOKEN](): void {
    localStorage.removeItem(this.refreshTokenKey);
    this.refreshToken = null;
  }

  @Mutation
  public [SignInTypes.SET_USER_DETAILS](profile?: UserDetails): void {
    if (profile) {
      const user = CryptoJS.AES.encrypt(
        JSON.stringify(profile),
        this.secretKey
      ).toString();

      localStorage.setItem(this.userKey, user);

      // this.userProfile = this.parseUserProfile();
    } else {
      this.userProfile = null;
    }
  }

  @Mutation
  public [SignInTypes.CLEAR_USER_DETAILS](): void {
    localStorage.removeItem(this.userKey);
    this.userProfile = null;
  }

  @Action({ rawError: true })
  public [SignInTypes.CLEAR_AUTH](): void {
    this.context.commit(SignInTypes.CLEAR_ACCESS_TOKEN);
    this.context.commit(SignInTypes.CLEAR_REFRESH_TOKEN);
    this.context.commit(SignInTypes.CLEAR_USER_DETAILS);
    router.replace("/login");
  }

  @Action({ rawError: true })
  public async [SignInTypes.SIGN_IN](
    payload: SignByUsernamePayload
  ): Promise<void> {
    this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, true);

    try {
      const result = await authService.signInWithUsername(payload);
      this.context.commit(SignInTypes.CLEAR_SIGN_IN_ERROR);
      this.context.commit(SignInTypes.SET_AUTHENTICATED, true);
      this.context.commit(SignInTypes.SET_ACCESS_TOKEN, <TokenPayload>{
        token: result.accessToken,
        expiry: result.accessTokenExpiresAt,
      });

      // refresh token
      // this.context.commit(SignInTypes.SET_REFRESH_TOKEN, <TokenPayload>{
      //   token: result.getRefreshToken(),
      //   expiry: result.getRefreshTokenExpiresAt()?.toDate() || new Date(),
      // });

      // user details
      this.context.commit(SignInTypes.SET_USER_DETAILS, result.user);

      await router.replace("/");
      // location.reload();
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Invalid username or Password" },
        { root: true }
      );
    } finally {
      this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.SIGN_OUT](): Promise<void> {
    this.context.commit(SignInTypes.SET_SIGN_OUT_LOADING, true);

    try {
      const authHeader = this.context.getters["authHeader"];
      await authService.signOut(authHeader);
      this.context.commit(SignInTypes.SET_AUTHENTICATED, false);
      this.context.dispatch(SignInTypes.CLEAR_AUTH);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to sign out" },
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(SignInTypes.SET_SIGN_OUT_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.REFRESH_TOKEN](): Promise<void> {
    if (!this.refreshTokenState.loading) {
      this.context.commit(SignInTypes.SET_REFRESH_TOKEN_LOADING, true);

      // try {
      //   const accessTokenPayload = await authService.refreshAuthToken({
      //     refresh_token: this.refreshToken?.token ?? "",
      //   });

      //   this.context.commit(SignInTypes.SET_ACCESS_TOKEN, accessTokenPayload);
      //   this.context.commit(SignInTypes.CLEAR_REFRESH_TOKEN_ERROR);
      //   router.replace(this.lastPath);
      //   location.reload();
      // } catch (e) {
      //   this.context.commit(RootTypes.openSnackbar, "Failed to refresh token", {
      //     root: true,
      //   });
      // } finally {
      //   this.context.commit(SignInTypes.SET_REFRESH_TOKEN_LOADING, false);
      // }
    }
  }

  // Forgot Password
  public [SignInTypes.FORGOT_PASSWORD_STATE]: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  @Mutation
  public [SignInTypes.SET_FORGOT_PASSWORD_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[SignInTypes.FORGOT_PASSWORD_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [SignInTypes.SET_FORGOT_PASSWORD_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[SignInTypes.FORGOT_PASSWORD_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [SignInTypes.SET_FORGOT_PASSWORD_ERROR](deleteError: string): void {
    this[SignInTypes.FORGOT_PASSWORD_STATE].error.errorMessage = deleteError;
    this[SignInTypes.FORGOT_PASSWORD_STATE].error.error = true;
  }

  @Action
  public async [SignInTypes.FORGOT_PASSWORD](email: string): Promise<void> {
    this.context.commit(SignInTypes.SET_FORGOT_PASSWORD_LOADING, true);

    try {
      await authService.forgotPassword(email);

      this.context.commit(
        RootTypes.openSnackbar,
        {
          message: `An email has been sent to ${email}. Check your email to reset your password`,
          color: "success",
        },
        { root: true }
      );

      this.context.commit(SignInTypes.SET_FORGOT_PASSWORD_DIALOG, false);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to send forgot password email" },
        { root: true }
      );
    } finally {
      this.context.commit(SignInTypes.SET_FORGOT_PASSWORD_LOADING, false);
    }
  }

  // Reset Password
  public [SignInTypes.RESET_PASSWORD_STATE]: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  @Mutation
  public [SignInTypes.SET_RESET_PASSWORD_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[SignInTypes.RESET_PASSWORD_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [SignInTypes.SET_RESET_PASSWORD_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[SignInTypes.RESET_PASSWORD_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [SignInTypes.SET_RESET_PASSWORD_ERROR](deleteError: string): void {
    this[SignInTypes.RESET_PASSWORD_STATE].error.errorMessage = deleteError;
    this[SignInTypes.RESET_PASSWORD_STATE].error.error = true;
  }

  @Action
  public async [SignInTypes.RESET_PASSWORD](
    payload: ResetPasswordPayload
  ): Promise<void> {
    this.context.commit(SignInTypes.SET_RESET_PASSWORD_LOADING, true);

    try {
      await authService.resetPassword(payload);

      this.context.commit(
        RootTypes.openSnackbar,
        {
          message: `Password has been reset successfully`,
          color: "success",
        },
        { root: true }
      );

      this.context.commit(SignInTypes.SET_RESET_PASSWORD_DIALOG, false);

      await router.replace("/login");
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to reset password" },
        { root: true }
      );
    } finally {
      this.context.commit(SignInTypes.SET_RESET_PASSWORD_LOADING, false);
    }
  }

  // Update Password
  public [SignInTypes.UPDATE_PASSWORD_STATE]: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  @Mutation
  public [SignInTypes.SET_UPDATE_PASSWORD_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[SignInTypes.UPDATE_PASSWORD_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [SignInTypes.SET_UPDATE_PASSWORD_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[SignInTypes.UPDATE_PASSWORD_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [SignInTypes.SET_UPDATE_PASSWORD_ERROR](deleteError: string): void {
    this[SignInTypes.UPDATE_PASSWORD_STATE].error.errorMessage = deleteError;
    this[SignInTypes.UPDATE_PASSWORD_STATE].error.error = true;
  }

  @Action({ rawError: true })
  public async [SignInTypes.UPDATE_PASSWORD](
    payload: UpdatePasswordPayload
  ): Promise<void> {
    this.context.commit(SignInTypes.SET_UPDATE_PASSWORD_LOADING, true);

    try {
      const authHeader = this.context.getters["authHeader"];
      await userService.updatePassword(authHeader, payload);

      this.context.commit(
        RootTypes.openSnackbar,
        {
          message: `Password has been updated successfully`,
          color: "success",
        },
        { root: true }
      );

      this.context.commit(SignInTypes.SET_UPDATE_PASSWORD_LOADING, false);
    } catch (e) {
      this.context.commit(SignInTypes.SET_UPDATE_PASSWORD_LOADING, false);

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to update password" },
        { root: true }
      );

      throw e;
    }
  }

  parseToken(key: string): TokenPayload | null {
    const accessTokenHash = localStorage.getItem(key);
    if (accessTokenHash) {
      const dToken = CryptoJS.AES.decrypt(
        accessTokenHash,
        this.secretKey
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(dToken) as TokenPayload;
    }
    return null;
  }

  parseUserProfile(): JsUserProfile | null {
    const userHash = localStorage.getItem(this.userKey);
    if (userHash) {
      try {
        const dToken = CryptoJS.AES.decrypt(userHash, this.secretKey).toString(
          CryptoJS.enc.Utf8
        );

        return JSON.parse(dToken) as JsUserProfile;
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  get authHeader(): string | null {
    const token = this.accessToken;
    if (token) {
      return `Bearer ${token.token}`;
    }

    return null;
  }

  get isLoggedIn(): boolean {
    if (this.accessToken) {
      return new Date() < new Date(this.accessToken.expiry);
    }

    return false;
  }

  get isRefreshable(): boolean {
    if (this.refreshToken) {
      return new Date() < new Date(this.refreshToken.expiry);
    }

    return false;
  }
}
export default Auth;
