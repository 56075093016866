import { AddSystemRole, SystemRole } from "@/store/modules/role/role.types";
import axios from "axios";
import { AddRoleRequest } from "i-tracking-proto/js/v1/role/rpc_add_role_pb";
import { GetRolesRequest } from "i-tracking-proto/js/v1/role/rpc_get_roles_pb";
import {
  PermissionsUpdate,
  UpdateRoleRequest,
} from "i-tracking-proto/js/v1/role/rpc_update_role_pb";

export async function addRole(
  authKey: string,
  payload: AddSystemRole
): Promise<SystemRole> {
  const request = new AddRoleRequest();
  request.setName(payload.name);
  request.setDescription(payload.description);
  request.setActive(payload.active);

  const permissions = new PermissionsUpdate();
  permissions.setAdmin(payload.permissions.admin);
  permissions.setViewTickets(payload.permissions.viewTickets);
  permissions.setViewAnalytics(payload.permissions.viewAnalytics);
  permissions.setViewAdminControl(payload.permissions.viewAdminControl);
  permissions.setViewReports(payload.permissions.viewReports);
  permissions.setViewUsers(payload.permissions.viewUsers);
  permissions.setViewDepartments(payload.permissions.viewDepartments);
  permissions.setViewRoles(payload.permissions.viewRoles);

  permissions.setModifyTickets(payload.permissions.modifyTickets);
  permissions.setModifyUsers(payload.permissions.modifyUsers);
  permissions.setModifyRoles(payload.permissions.modifyRoles);
  permissions.setModifyDepartments(payload.permissions.modifyDepartments);
  permissions.setModifyReports(payload.permissions.modifyReports);
  request.setPermissions(permissions);

  const response = await axios.post<SystemRole>(
    "/v1/role",
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function updateRole(
  authKey: string,
  payload: SystemRole
): Promise<SystemRole> {
  const request = new UpdateRoleRequest();
  request.setId(payload.id);
  request.setName(payload.name);
  request.setDescription(payload.description);

  const permissions = new PermissionsUpdate();
  permissions.setAdmin(payload.permissions.admin);
  permissions.setViewTickets(payload.permissions.viewTickets);
  permissions.setViewAnalytics(payload.permissions.viewAnalytics);
  permissions.setViewAdminControl(payload.permissions.viewAdminControl);
  permissions.setViewReports(payload.permissions.viewReports);
  permissions.setViewUsers(payload.permissions.viewUsers);
  permissions.setViewDepartments(payload.permissions.viewDepartments);
  permissions.setViewRoles(payload.permissions.viewRoles);

  permissions.setModifyTickets(payload.permissions.modifyTickets);
  permissions.setModifyUsers(payload.permissions.modifyUsers);
  permissions.setModifyRoles(payload.permissions.modifyRoles);
  permissions.setModifyDepartments(payload.permissions.modifyDepartments);
  permissions.setModifyReports(payload.permissions.modifyReports);
  request.setPermissions(permissions);

  const response = await axios.patch<SystemRole>(
    `/v1/role`,
    request.toObject(),
    {
      headers: { "Content-Type": "application/json", Authorization: authKey },
    }
  );

  return response.data;
}

export async function deleteRole(
  authKey: string,
  payload: SystemRole
): Promise<void> {
  await axios.delete<void>(`/v1/role/${payload.id}`, {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });
}

export async function getRoles(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<SystemRole[]> {
  const addSystemRoleRequest = new GetRolesRequest();
  addSystemRoleRequest.setPage(page);
  addSystemRoleRequest.setLimit(offset);
  addSystemRoleRequest.setQuery(query);

  const response = await axios.get<SystemRole[]>("/v1/roles", {
    headers: { "Content-Type": "application/json", Authorization: authKey },
  });

  return response.data;
}

export default {
  addRole,
  getRoles,
  updateRole,
  deleteRole,
};
