import { DefaultState } from "@/types/types";

export type SiteStation = {
  id: number;
  name: string;
  region: string;
  description: string;
  zone: string;
  active: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type AddSiteStation = {
  name: string;
  region: string;
  description: string;
  zone: string;
  active: boolean;
};

export type SitesState = {
  sites: SiteStation[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetSitesResult = {
  sites: SiteStation[];
  pages: number;
};

export enum SiteTypes {
  // States
  SITES = "SITES",
  SITE_DATA = "SITE_DATA",
  ADD_SITE_STATE = "ADD_SITE_STATE",
  UPDATE_SITE_STATE = "UPDATE_SITE_STATE",
  DELETE_SITE_STATE = "DELETE_SITE_STATE",
  LOADING_SITES_STATE = "LOADING_SITES_STATE",

  // Actions
  LOAD_SITES = "LOAD_SITES",
  ADD_SITE = "ADD_SITE",
  DELETE_SITE = "DELETE_SITE",
  UPDATE_SITE = "UPDATE_SITE",

  // Mutations
  SET_LOADING_SITE = "SET_LOADING_SITE",

  INSERT_SITE = "INSERT_SITE",
  INSERT_SITES = "INSERT_SITES",
  REMOVE_SITE = "REMOVE_SITE",

  SET_TICKET_SITE_DATA = "SET_TICKET_SITE_DATA",
  SET_SITE_PAGES = "SET_SITE_PAGES",
  SET_ADD_SITE_DIALOG_REF = "SET_ADD_SITE_DIALOG_REF",

  SET_ADD_SITE_LOADING = "SET_ADD_SITE_LOADING",
  SET_ADD_SITE_DIALOG = "SET_ADD_SITE_DIALOG",
  SET_ADD_SITE_ERROR = "SET_ADD_SITE_ERROR",

  SET_UPDATE_SITE_LOADING = "SET_UPDATE_SITE_LOADING",
  SET_UPDATE_SITE_DIALOG = "SET_UPDATE_SITE_DIALOG",
  SET_UPDATE_SITE_ERROR = "SET_UPDATE_SITE_ERROR",

  SET_DELETE_SITE_LOADING = "SET_DELETE_SITE_LOADING",
  SET_DELETE_SITE_DIALOG = "SET_DELETE_SITE_DIALOG",
  SET_DELETE_SITE_ERROR = "SET_DELETE_SITE_ERROR",
}
