import { DefaultState } from "@/types/types";

export type UserTicket = {
  id: number;
  category: string;
  ticketNumber: string;
  issue: string;
  caseNumber: string;
  description: string;
  product: string;
  site: string;
  reportingDate: Date;
  expectedClosureDate?: Date;
  actualClosureDate?: Date;
  assignee?: string;
  sla: number;
  priority: string;
  status: string;
  resolution?: string;
  rootCause?: string;
  createdAt: Date;
  updatedAt?: Date;
  loading: boolean;
};

export type AddUserTicket = {
  category: string;
  issue: string;
  ticketNumber: string;
  description: string;
  product: string;
  site: string;
  assignee?: string;
  priority: string;
};

export type TicketsState = {
  departments: UserTicket[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type GetTicketsResult = {
  tickets: UserTicket[];
  pages: number;
};

export type UpdateTicketStatus = {
  ticket: UserTicket;
  status: string;
};

export type CloseTicketRequest = {
  ticket: UserTicket;
  details: CloseTicketDetails;
};

export type CloseTicketDetails = {
  rootCause: string;
  resolution: string;
};

export enum TicketTypes {
  // States
  TICKET_STATUSES = "TICKET_STATUSES",
  TICKETS = "TICKETS",
  TOP_TICKETS = "TOP_TICKETS",
  TICKET_DATA = "TICKET_DATA",
  VIEW_TICKET_STATE = "VIEW_TICKET_STATE",
  ADD_TICKET_STATE = "ADD_TICKET_STATE",
  UPDATE_TICKET_STATE = "UPDATE_TICKET_STATE",
  CLOSE_TICKET_STATE = "CLOSE_TICKET_STATE",
  DELETE_TICKET_STATE = "DELETE_TICKET_STATE",
  LOADING_TICKETS_STATE = "LOADING_TICKETS_STATE",
  LOADING_TOP_TICKETS = "LOADING_TOP_TICKETS",

  // Actions
  LOAD_TOP_TICKETS = "LOAD_TOP_TICKETS",
  LOAD_TICKETS = "LOAD_TICKETS",
  ADD_TICKET = "ADD_TICKET",
  DELETE_TICKET = "DELETE_TICKET",
  UPDATE_TICKET = "UPDATE_TICKET",
  CLOSE_TICKET = "CLOSE_TICKET",

  // Mutations
  SET_PAGE_DATA = "SET_PAGE_DATA",
  SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
  SET_LOADING_TICKET = "SET_LOADING_TICKET",
  SET_LOADING_TOP_TICKETS = "SET_LOADING_TOP_TICKETS",

  INSERT_TICKET = "INSERT_TICKET",
  INSERT_TICKETS = "INSERT_TICKETS",
  INSERT_TOP_TICKETS = "INSERT_TOP_TICKETS",
  REMOVE_TICKET = "REMOVE_TICKET",

  SET_TICKET_TICKET_DATA = "SET_TICKET_TICKET_DATA",
  SET_TICKET_PAGES = "SET_TICKET_PAGES",
  SET_ADD_TICKET_DIALOG_REF = "SET_ADD_TICKET_DIALOG_REF",

  SET_VIEW_TICKET_DIALOG = "SET_VIEW_TICKET_DIALOG",

  SET_ADD_TICKET_LOADING = "SET_ADD_TICKET_LOADING",
  SET_ADD_TICKET_DIALOG = "SET_ADD_TICKET_DIALOG",
  SET_ADD_TICKET_ERROR = "SET_ADD_TICKET_ERROR",

  SET_UPDATE_TICKET_LOADING = "SET_UPDATE_TICKET_LOADING",
  SET_UPDATE_TICKET_DIALOG = "SET_UPDATE_TICKET_DIALOG",
  SET_UPDATE_TICKET_ERROR = "SET_UPDATE_TICKET_ERROR",

  SET_DELETE_TICKET_LOADING = "SET_DELETE_TICKET_LOADING",
  SET_DELETE_TICKET_DIALOG = "SET_DELETE_TICKET_DIALOG",
  SET_DELETE_TICKET_ERROR = "SET_DELETE_TICKET_ERROR",

  SET_CLOSE_TICKET_LOADING = "SET_CLOSE_TICKET_LOADING",
  SET_CLOSE_TICKET_DIALOG = "SET_CLOSE_TICKET_DIALOG",
  SET_CLOSE_TICKET_ERROR = "SET_CLOSE_TICKET_ERROR",

  UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS",
  SET_TICKET_LOADING = "SET_TICKET_LOADING",

  RELOAD_ANALYTICS = "RELOAD_ANALYTICS",
}

export type PaginatedTickets = {
  data: UserTicket[];
  count: number;
};
