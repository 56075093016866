import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import { ReportFilter, ReportState, ReportTypes } from "./reports.types";
import reportsService from "@/services/reports.service";
import { AxiosError } from "axios";
import { SignInTypes } from "../auth/auth.types";
import { UserTicket } from "../ticket/ticket.types";

@Module({ namespaced: true })
export default class Reports extends VuexModule {
  public [ReportTypes.TICKET_REPORT_FILTER]: ReportFilter = {};

  @Mutation
  public [ReportTypes.SET_TICKET_REPORT_FILTER](filter: ReportFilter): void {
    this[ReportTypes.TICKET_REPORT_FILTER] = filter;
  }

  // Product Analytics
  public [ReportTypes.TICKET_REPORTS]: ReportState = {
    tickets: [],
    loading: false,
  };

  public [ReportTypes.DOWNLOADING_CSV_TICKET_REPORTS] = false;

  @Mutation
  public [ReportTypes.SET_DOWNLOADING_CSV_TICKET_REPORTS](
    isDownloading: boolean
  ): void {
    this[ReportTypes.DOWNLOADING_CSV_TICKET_REPORTS] = isDownloading;
  }

  @Mutation
  public [ReportTypes.SET_TICKET_REPORTS](tickets: UserTicket[]): void {
    this[ReportTypes.TICKET_REPORTS].tickets = tickets;
  }

  @Mutation
  public [ReportTypes.SET_LOADING_TICKET_REPORTS](isLoading: boolean): void {
    this[ReportTypes.TICKET_REPORTS].loading = isLoading;
  }

  @Action({ rawError: true })
  public async [ReportTypes.LOAD_TICKET_REPORTS](
    filter: ReportFilter = {}
  ): Promise<void> {
    if (
      this[ReportTypes.TICKET_REPORTS].tickets.length > 0 &&
      filter.force !== true
    )
      return;

    if (filter.force !== true) {
      // merge two filters with priority to passed one
      filter = {
        category:
          filter.category || this[ReportTypes.TICKET_REPORT_FILTER].category,
        assignee:
          filter.assignee || this[ReportTypes.TICKET_REPORT_FILTER].assignee,
        priority:
          filter.priority || this[ReportTypes.TICKET_REPORT_FILTER].priority,
        product:
          filter.product || this[ReportTypes.TICKET_REPORT_FILTER].product,
        status: filter.status || this[ReportTypes.TICKET_REPORT_FILTER].status,
        startDate:
          filter.startDate || this[ReportTypes.TICKET_REPORT_FILTER].startDate,
        endDate:
          filter.endDate || this[ReportTypes.TICKET_REPORT_FILTER].endDate,
      };
    }

    this.context.commit(ReportTypes.SET_TICKET_REPORT_FILTER, filter);
    this.context.commit(ReportTypes.SET_LOADING_TICKET_REPORTS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const reports = await reportsService.getTicketReports(authHeader, filter);
      this.context.commit(ReportTypes.SET_TICKET_REPORTS, reports);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        "Failed to load ticket reports",
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(ReportTypes.SET_LOADING_TICKET_REPORTS, false);
    }
  }

  @Action({ rawError: true })
  public async [ReportTypes.DOWNLOAD_CSV_TICKET_REPORTS](
    filter: ReportFilter = {}
  ): Promise<void> {
    if (this[ReportTypes.DOWNLOADING_CSV_TICKET_REPORTS]) return;

    this.context.commit(ReportTypes.SET_DOWNLOADING_CSV_TICKET_REPORTS, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const report = await reportsService.downloadCSVTicketReports(
        authHeader,
        filter
      );

      // create file link in browser's memory
      const href = URL.createObjectURL(report.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", report.name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        {
          message: "Failed to download ticket reports",
        },
        {
          root: true,
        }
      );
    } finally {
      this.context.commit(
        ReportTypes.SET_DOWNLOADING_CSV_TICKET_REPORTS,
        false
      );
    }
  }
}
