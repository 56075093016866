import productService from "@/services/product.service";
import { DefaultState, Pagination } from "@/types/types";
import { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignInTypes } from "../auth/auth.types";
import { RootTypes } from "../root.types";
import { AddSystemProduct, ProductTypes, SystemProduct } from "./product.types";

@Module({ namespaced: true })
class ProductModule extends VuexModule {
  public [ProductTypes.PRODUCTS]: SystemProduct[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public addProductRef = 0;

  public [ProductTypes.PRODUCT_DATA]: SystemProduct = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  public [ProductTypes.ADD_PRODUCT_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ProductTypes.DELETE_PRODUCT_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ProductTypes.UPDATE_PRODUCT_STATE]: DefaultState = Object.assign(
    {},
    this.defaultState
  );

  public [ProductTypes.LOADING_PRODUCTS_STATE] = false;

  @Mutation
  public [ProductTypes.SET_ADD_PRODUCT_DIALOG_REF](ref: number): void {
    this.addProductRef = ref;
  }

  @Mutation
  public [ProductTypes.SET_TICKET_PRODUCT_DATA](data: SystemProduct): void {
    this[ProductTypes.PRODUCT_DATA] = data;
  }

  // Set card pages count
  @Mutation
  public [ProductTypes.SET_PRODUCT_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [ProductTypes.SET_LOADING_PRODUCT](isLoadingCard: boolean): void {
    this[ProductTypes.LOADING_PRODUCTS_STATE] = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [ProductTypes.SET_ADD_PRODUCT_DIALOG](
    isAddingCardDialog: boolean
  ): void {
    this[ProductTypes.ADD_PRODUCT_STATE].dialog = isAddingCardDialog;
  }

  @Mutation
  public [ProductTypes.INSERT_PRODUCTS](products: SystemProduct[]): void {
    this[ProductTypes.PRODUCTS].splice(0, this[ProductTypes.PRODUCTS].length);
    this[ProductTypes.PRODUCTS].push(...products);
  }

  @Mutation
  public [ProductTypes.SET_ADD_PRODUCT_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this[ProductTypes.ADD_PRODUCT_STATE].loading = isAddingCardLoading;
  }

  @Mutation
  public [ProductTypes.SET_ADD_PRODUCT_ERROR](addError: string): void {
    this[ProductTypes.ADD_PRODUCT_STATE].error.errorMessage = addError;
    this[ProductTypes.ADD_PRODUCT_STATE].error.error = true;
  }

  // Delete Card
  @Mutation
  public [ProductTypes.SET_DELETE_PRODUCT_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this[ProductTypes.DELETE_PRODUCT_STATE].dialog = isDeletingCardDialog;
  }

  @Mutation
  public [ProductTypes.SET_DELETE_PRODUCT_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this[ProductTypes.DELETE_PRODUCT_STATE].loading = isDeletingCardLoading;
  }

  @Mutation
  public [ProductTypes.SET_DELETE_PRODUCT_ERROR](deleteError: string): void {
    this[ProductTypes.DELETE_PRODUCT_STATE].error.errorMessage = deleteError;
    this[ProductTypes.DELETE_PRODUCT_STATE].error.error = true;
  }

  // Update Card
  @Mutation
  public [ProductTypes.SET_UPDATE_PRODUCT_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this[ProductTypes.UPDATE_PRODUCT_STATE].dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [ProductTypes.SET_UPDATE_PRODUCT_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this[ProductTypes.UPDATE_PRODUCT_STATE].loading = isUpdatingCardLoading;
  }

  @Mutation
  public [ProductTypes.SET_UPDATE_PRODUCT_ERROR](updateError: string): void {
    this[ProductTypes.UPDATE_PRODUCT_STATE].error.errorMessage = updateError;
    this[ProductTypes.UPDATE_PRODUCT_STATE].error.error = true;
  }

  // Insert Card
  @Mutation
  public [ProductTypes.INSERT_PRODUCT](product: SystemProduct): void {
    const index = this[ProductTypes.PRODUCTS]
      .map((x) => x.id)
      .indexOf(product.id);

    if (index > -1) {
      this[ProductTypes.PRODUCTS].splice(index, 1, product);
    } else {
      this[ProductTypes.PRODUCTS].splice(0, 0, product);
    }
  }

  // Remove Card
  @Mutation
  public [ProductTypes.REMOVE_PRODUCT](product: SystemProduct): void {
    const index = this[ProductTypes.PRODUCTS]
      .map((x) => x.id)
      .indexOf(product.id);

    if (index > -1) {
      this[ProductTypes.PRODUCTS].splice(index, 1);
    }
  }

  // Load Cards
  @Action
  public async [ProductTypes.LOAD_PRODUCTS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    if (this[ProductTypes.PRODUCTS].length > 0) return;

    this.context.commit(ProductTypes.SET_LOADING_PRODUCT, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const response = await productService.getProducts(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(ProductTypes.INSERT_PRODUCTS, response);
      // this.context.commit(ProductTypes.SET_PRODUCT_PAGES, response.pages);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading products" },
        { root: true }
      );
    } finally {
      this.context.commit(ProductTypes.SET_LOADING_PRODUCT, false);
    }
  }
  // Add Card
  @Action
  public async [ProductTypes.ADD_PRODUCT](
    product: AddSystemProduct
  ): Promise<void> {
    this.context.commit(ProductTypes.SET_ADD_PRODUCT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await productService.addProduct(authHeader, product);
      this.context.commit(ProductTypes.INSERT_PRODUCT, fm);
      this.context.commit(
        ProductTypes.SET_ADD_PRODUCT_DIALOG_REF,
        this.addProductRef + 1
      );
      this.context.commit(ProductTypes.SET_ADD_PRODUCT_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Product Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(ProductTypes.SET_ADD_PRODUCT_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [ProductTypes.DELETE_PRODUCT](): Promise<void> {
    this.context.commit(ProductTypes.SET_DELETE_PRODUCT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await productService.deleteProduct(
        authHeader,
        this[ProductTypes.PRODUCT_DATA]
      );
      this.context.commit(
        ProductTypes.REMOVE_PRODUCT,
        this[ProductTypes.PRODUCT_DATA]
      );
      this.context.commit(ProductTypes.SET_DELETE_PRODUCT_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        ProductTypes.SET_DELETE_PRODUCT_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(ProductTypes.SET_DELETE_PRODUCT_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [ProductTypes.UPDATE_PRODUCT](
    ticketProduct: SystemProduct
  ): Promise<void> {
    this.context.commit(ProductTypes.SET_UPDATE_PRODUCT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const fm = await productService.updateProduct(authHeader, ticketProduct);
      this.context.commit(ProductTypes.INSERT_PRODUCT, fm);
      this.context.commit(ProductTypes.SET_UPDATE_PRODUCT_DIALOG, false);
    } catch (e) {
      // Signout if 401
      if (e instanceof AxiosError && e.response?.status === 401) {
        this.context.dispatch(
          `${SignInTypes.MODULE}/${SignInTypes.CLEAR_AUTH}`,
          null,
          { root: true }
        );
      }

      this.context.commit(
        ProductTypes.SET_UPDATE_PRODUCT_ERROR,
        "Failed to update product"
      );
    } finally {
      this.context.commit(ProductTypes.SET_UPDATE_PRODUCT_LOADING, false);
    }
  }
}

export default ProductModule;
